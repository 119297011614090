import {Box, Button, withStyles} from '@material-ui/core';
import React, {useCallback, useEffect, useReducer} from 'react';
import Collapsible from 'react-collapsible';

import elasticSearchReducer, {initialState} from '../../reducers/elasticSearchReducer';
import * as ActionCreators from '../../store/actioncreators';
import autodispatch from '../../store/autodispatch';

const styles = (theme) => ({
    root: {
        padding: theme.spacing(4),
    },
    stickyRight: {
        marginLeft: 'auto',
    },
    timeBox: {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        color: '#444',
        marginRight: 5,
        padding: '0 5px',
    },
    collapsible: {
        wordBreak: 'break-all',
        '&:nth-child(even)': {
            backgroundColor: '#b1b1b1',
        },
        '&:nth-child(odd)': {
            backgroundColor: '#d0cece',
        },
    },
    triggerBar: {
        display: 'grid',
        gridTemplateColumns: '3fr 2fr 1fr 12fr',
        marginLeft: '-6px',
    },
    openTrigger: {
        backgroundColor: '#FF7519',
    },
    auditLogContent: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
    },
    auditLogContentBox: {
        margin: 5,
    },
    keyBox: {
        fontWeight: 'bold',
        padding: 5,
        backgroundColor: '#efefef',
    },
    twoFrameGrid: {
        display: 'grid',
        padding: 5,
        gridTemplateColumns: '1fr 1fr',
    },
    fourFrameGrid: {
        display: 'grid',
        padding: 5,
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
    codeBox: {
        padding: 5,
        width: 300,
    },
});

function AuditLogOverzicht(props) {
    const {endpoint, filter, version, footer, classes, autoOpen, setMaxLogSize, maxLogSize, increaseLogBy} = props;
    const [state, dispatch] = useReducer(elasticSearchReducer, initialState);
    const {results} = state;
    const {fetchData} = autodispatch(ActionCreators, dispatch);

    const formatData = (results) => results.toJS();

    const doFetch = useCallback(() => {
        if (version > 0) {
            fetchData({endpoint, filter});
        }
    }, [endpoint, version]);

    useEffect(() => {
        doFetch();
    }, [endpoint, version]);

    const createRequest = (request) => {
        if (request.put) {
            const put = request.put;
            return (
                <Box>
                    <Box className={classes.keyBox}>PUT</Box>
                    <Box className={classes.twoFrameGrid}>
                        <Box>
                            <b>Database ID</b>
                            <Box>{put.info.id}</Box>
                        </Box>
                        <Box>
                            <b>Object type</b>
                            <Box>{put.info.objecttype}</Box>
                        </Box>
                    </Box>
                    <Box className={classes.codeBox}>
                        <b>Difference</b>
                        <pre>
                            <code>{JSON.stringify(put.difference, undefined, 2)}</code>
                        </pre>
                    </Box>
                </Box>
            );
        }

        if (request.post) {
            return (
                <Box>
                    <Box className={classes.keyBox}>POST</Box>
                    <Box className={classes.codeBox}>
                        <pre>
                            <code>{JSON.stringify(request.post, undefined, 2)}</code>
                        </pre>
                    </Box>
                </Box>
            );
        }

        if (request.delete) {
            return (
                <Box>
                    <Box className={classes.keyBox}>DELETE</Box>
                    <Box className={classes.twoFrameGrid}>
                        <Box>
                            <b>Database ID</b>
                            <Box>{request.delete.info.id}</Box>
                        </Box>
                        <Box>
                            <b>Object type</b>
                            <Box>{request.delete.info.objecttype}</Box>
                        </Box>
                    </Box>
                    <Box className={classes.codeBox}>
                        <pre>
                            <code>{JSON.stringify(request.delete, undefined, 2)}</code>
                        </pre>
                    </Box>
                </Box>
            );
        }
        return (
            <Box>
                <Box className={classes.key}>Request type not supported</Box>
                <pre>
                    <code>{JSON.stringify(request, undefined, 2)}</code>
                </pre>
            </Box>
        );
    };

    return (
        <Box>
            <Box className={classes.maxWidth100} display={'flex'} flexGrow={1} flexDirection={'column'}>
                {results.size > 0 ? (
                    formatData(results).map((result) => (
                        <Collapsible
                            trigger={
                                <Box className={classes.triggerBar}>
                                    <Box className={classes.timeBox}>{result.requested_on}</Box>
                                    <Box>{result.account_name}</Box>
                                    <Box>{result.request_type}</Box>
                                    <Box>{result.url}</Box>
                                </Box>
                            }
                            className={classes.collapsible}
                            triggerOpenedClassName={classes.openTrigger}
                            transitionTime={200}
                            classParentString={'Collapsible'}
                            open={autoOpen === true}
                        >
                            <Box className={classes.auditLogContent}>
                                {Object.keys(result)
                                    .filter(
                                        (k) =>
                                            !['request_type', 'account_name', 'url', 'request', 'warning', 'auth_role'].includes(
                                                k
                                            )
                                    )
                                    .map((k) => (
                                        <Box className={classes.auditLogContentBox}>
                                            <Box className={classes.keyBox}>{k}</Box>
                                            <Box className={classes.twoFrameGrid}>{JSON.stringify(result[k])}</Box>
                                        </Box>
                                    ))}
                            </Box>
                            <Box className={classes.auditLogContentBox}>
                                <Box className={classes.keyBox}>Rechten van de beheerder</Box>
                                <Box className={classes.fourFrameGrid}>
                                    {result.auth_role.rechten?.map((r) => (
                                        <Box>{r}</Box>
                                    ))}
                                </Box>
                            </Box>
                            <Box className={classes.auditLogContentBox}>{createRequest(result.request)}</Box>
                            <Box className={classes.auditLogContentBox}>
                                <Box className={classes.keyBox}>Warning</Box>
                                <Box className={classes.twoFrameGrid}>{result.warning}</Box>
                            </Box>
                        </Collapsible>
                    ))
                ) : (
                    <div>Geen activiteit gevonden</div>
                )}
                <span className={classes.expandArrow}>{footer(state)}</span>
            </Box>
            {maxLogSize && increaseLogBy && results.size === maxLogSize && (
                <Button
                    className={classes.stickyRight}
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                        setMaxLogSize(maxLogSize + increaseLogBy);
                    }}
                >
                    <i className="material-icons">expand_more</i>
                </Button>
            )}
        </Box>
    );
}

export default withStyles(styles)(AuditLogOverzicht);
