import DateFnsUtils from '@date-io/date-fns';
import {Grid, Paper, TextField, Typography, withStyles} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Icon from '@material-ui/core/Icon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {nl} from 'date-fns/locale';
import React from 'react';
import {compose} from 'recompose';
import {Recht, PoRole} from 'shared-types';

import AutoComplete from '../../components/autocomplete';
import DataTable from '../../components/datatable';
import {ArrayRenderer, DateToLocalDateRenderer, LinkRenderer} from '../../components/datatable/contentrenderers';
import SearchTextField from '../../components/input/searchtextfield';
import {withPermissions} from '../../components/permissions/withpermissions';
import SearchButton from '../../components/searchbutton';
import SearchQuerySetter from '../../components/searchquerysetter';
import {Types, parseURLSearchParams} from '../../services/util';
import defaultConnect from '../../store/connector';
import DeletePoLicense from './deletepolicense';

const dateFns = new DateFnsUtils();

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2)
    },
    formInputBox: {
        width: 250,
        margin: theme.spacing(1)
    },
    showFailedOnly: {
        marginLeft: 'auto',
        marginRight: theme.spacing(1)
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    chip: {
        margin: 2
    },
    noLabel: {
        marginTop: theme.spacing(3)
    },
    formDateBox: {
        margin: '0 8px 0 8px'
    }
});

const Info = props => (
    <a title={props.rowData && props.rowData.createdAt}>
        <Icon>access_time</Icon>
    </a>
);
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250
        }
    }
};

const groepnummers = [1, 2, 3, 4, 5, 6, 7, 8];

class PoLicentiesOverzicht extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            filters: parseURLSearchParams(
                {
                    q: Types.STRING,
                    product: Types.NUMBER,
                    organisation: Types.NUMBER,
                    groepen: Types.ARRAY,
                    rollen: Types.ARRAY,
                    fromDate: Types.DATE,
                    toDate: Types.DATE
                },
                this.props.history
            )
        };
    }

    reloadDataTable() {
        this.setState({version: Number(Date.now())});
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const {classes} = this.props;

        return (
            <Paper className={classes.root}>
                <SearchQuerySetter filters={this.state.filters} history={this.props.history} />
                <Typography component="h1" variant="h5">
                    PO licenties
                </Typography>
                <Grid container justify="flex-start" className={classes.inputContainer}>
                    <Grid item>
                        <FormControl>
                            <SearchTextField
                                fullWidth={true}
                                className={classes.formInputBox}
                                label="BasispoortId, EckId of ReferenceId"
                                value={this.state.filters.q}
                                onEnter={() => this.reloadDataTable()}
                                onChange={v => {
                                    this.setState({filters: {...this.state.filters, q: v}});
                                }}
                                autoFocus={true}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <AutoComplete
                                name={'product'}
                                endpoint={'products'}
                                value={this.state.filters.product}
                                labelKey={'toString'}
                                secondLabelKey={'ean'}
                                valueKey={'id'}
                                placeholder={'Product...'}
                                onChange={(property, value) => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            product: value && value.id
                                        }
                                    });
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <AutoComplete
                                name={'organisation'}
                                endpoint={'organisations'}
                                endpointFilter={{type: 'School'}}
                                value={this.state.filters.organisation}
                                labelKey={'name'}
                                secondLabelKey={'esn'}
                                placeholder={'School...'}
                                onChange={(property, value) => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            organisation: value && value.id
                                        }
                                    });
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <Select
                                labelId="groepInputLabel"
                                multiple
                                value={this.state.filters.groepen}
                                onChange={event => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            groepen: event.target.value
                                        }
                                    });
                                }}
                                input={<TextField margin="dense" variant="outlined" label="Groep" />}
                                renderValue={selected => selected.sort().join(', ')}
                                MenuProps={MenuProps}
                            >
                                {groepnummers.map(name => (
                                    <MenuItem key={name} value={name}>
                                        <Checkbox checked={this.state.filters.groepen.indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <Select
                                labelId="groepInputLabel"
                                multiple
                                value={this.state.filters.rollen}
                                onChange={event => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            rollen: event.target.value
                                        }
                                    });
                                }}
                                input={<TextField margin="dense" variant="outlined" label="Rol" />}
                                renderValue={selected => selected.sort().join(', ')}
                                MenuProps={MenuProps}
                            >
                                {[PoRole.Teacher, PoRole.Student].map(name => (
                                    <MenuItem key={name} value={name}>
                                        <Checkbox checked={this.state.filters.rollen.indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formDateBox}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                                <KeyboardDatePicker
                                    autoOk
                                    format="dd-MM-yyyy"
                                    label="Aangemaakt vanaf"
                                    margin="dense"
                                    inputVariant="outlined"
                                    onChange={e => {
                                        if (e === null) {
                                            this.setState({
                                                filters: {
                                                    ...this.state.filters,
                                                    fromDate: null
                                                }
                                            });
                                        } else if (dateFns.isValid(e)) {
                                            this.setState({
                                                filters: {
                                                    ...this.state.filters,
                                                    fromDate: dateFns.date(dateFns.format(e, 'yyyy-MM-dd'))
                                                }
                                            });
                                        }
                                    }}
                                    value={this.state.filters.fromDate}
                                    invalidDateMessage=""
                                    clearable
                                />
                            </MuiPickersUtilsProvider>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formDateBox}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                                <KeyboardDatePicker
                                    autoOk
                                    format="dd-MM-yyyy"
                                    label="Aangemaakt tot"
                                    margin="dense"
                                    inputVariant="outlined"
                                    onChange={e => {
                                        if (e === null) {
                                            this.setState({
                                                filters: {
                                                    ...this.state.filters,
                                                    toDate: null
                                                }
                                            });
                                        } else if (dateFns.isValid(e)) {
                                            this.setState({
                                                filters: {
                                                    ...this.state.filters,
                                                    toDate: dateFns.date(dateFns.format(e, 'yyyy-MM-dd'))
                                                }
                                            });
                                        }
                                    }}
                                    value={this.state.filters.toDate}
                                    invalidDateMessage=""
                                    clearable
                                />
                            </MuiPickersUtilsProvider>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <SearchButton onClick={this.reloadDataTable.bind(this)} />
                    </Grid>
                </Grid>
                <Grid container>
                    {
                        <DataTable
                            version={this.state.version}
                            endpoint="policenses"
                            striped
                            filter={this.state.filters}
                            columns={[
                                {
                                    label: '',
                                    dataKey: 'ACTIONS',
                                    disableSort: true,
                                    component: <Info />,
                                    styling: {justifyContent: 'center'}
                                },
                                {label: 'Product', dataKey: 'product.toString'},
                                {label: 'EAN', dataKey: 'product.ean'},
                                {label: 'BasispoortId', dataKey: 'basispoortId'},
                                {label: 'EckId', dataKey: 'eckId'},
                                {label: 'Groep(en)', dataKey: 'groups', component: <ArrayRenderer />},
                                {
                                    label: 'School',
                                    dataKey: 'organisation.toString',
                                    component: (
                                        <LinkRenderer
                                            url="organisaties/<id>/extended-view"
                                            data={{id: 'organisation.id'}}
                                            title="organisation.esn"
                                        />
                                    )
                                },
                                {
                                    label: 'Startdatum',
                                    dataKey: 'startDate',
                                    component: <DateToLocalDateRenderer />
                                },
                                {
                                    label: 'Einddatum',
                                    dataKey: 'endDate',
                                    component: <DateToLocalDateRenderer />
                                },
                                {label: 'Referentie', dataKey: 'referenceId'},
                                {
                                    label: '',
                                    dataKey: 'HMM',
                                    disableSort: true,
                                    component: <DeletePoLicense handleRefresh={() => this.reloadDataTable()} />,
                                    styling: {justifyContent: 'center'}
                                }
                            ]}
                            csv={true}
                            customCsvHeaders={[
                                {
                                    label: 'Product',
                                    dataKey: 'product.title'
                                },
                                {
                                    label: 'School',
                                    dataKey: 'organisation.name'
                                }
                            ]}
                        />
                    }
                </Grid>
            </Paper>
        );
    }
}

export default compose(
    defaultConnect,
    withPermissions(Recht.POLICENTIES_INZIEN),
    withStyles(styles)
)(PoLicentiesOverzicht);
