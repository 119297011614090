import {Fab, Grid, IconButton, MenuItem, Paper, SvgIcon, TextField, Typography, withStyles} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React from 'react';
import {Link} from 'react-router-dom';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import AutoComplete from '../../components/autocomplete';
import DataTable from '../../components/datatable';
import {InfoResolveRenderer} from '../../components/datatable/contentrenderers';
import SearchTextField from '../../components/input/searchtextfield';
import {withPermissions} from '../../components/permissions/withpermissions';
import SearchButton from '../../components/searchbutton';
import SearchQuerySetter from '../../components/searchquerysetter';
import {Types, parseURLSearchParams} from '../../services/util';
import defaultConnect from '../../store/connector';
import {getSchoolYearName} from '../../services/schoolyear';

const styles = (theme) => ({
    root: {
        padding: theme.spacing(4),
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    formInputBox: {
        maxWidth: 250,
        minWidth: 250,
        margin: theme.spacing(1),
    },
});

const GoToContractBalance = ({rowData}) => {
    const {schoolyearName, schoolId, schoolName, schoolEsn, schoolBrin} = rowData;

    // the svg icon is the BalanceIcon from material-ui v5 (we use v4)
    return (
        <Link
            to={`/contractbalances?schoolyearName=${schoolyearName}&schoolId=${schoolId}&schoolName=${schoolName}&schoolEsn=${schoolEsn}&schoolBrin=${schoolBrin}`}
        >
            <IconButton variant="outlined" size="small" color="primary">
                <SvgIcon>
                    <path d="M13,7.83c0.85-0.3,1.53-0.98,1.83-1.83H18l-3,7c0,1.66,1.57,3,3.5,3s3.5-1.34,3.5-3l-3-7h2V4h-6.17 C14.42,2.83,13.31,2,12,2S9.58,2.83,9.17,4L3,4v2h2l-3,7c0,1.66,1.57,3,3.5,3S9,14.66,9,13L6,6h3.17c0.3,0.85,0.98,1.53,1.83,1.83 V19H2v2h20v-2h-9V7.83z M20.37,13h-3.74l1.87-4.36L20.37,13z M7.37,13H3.63L5.5,8.64L7.37,13z M12,6c-0.55,0-1-0.45-1-1 c0-0.55,0.45-1,1-1s1,0.45,1,1C13,5.55,12.55,6,12,6z" />
                </SvgIcon>
            </IconButton>
        </Link>
    );
};

const GoToContractSpecifications = ({rowData}) => {
    const {contractReferenceId, schoolyearName, schoolId, subjectId} = rowData;

    return (
        <Link
            to={`/contractspecifications?contractReferenceId=${contractReferenceId}&schoolyearName=${schoolyearName}&schoolId=${schoolId}&subjectId=${subjectId}`}
        >
            <IconButton variant="outlined" size="small" color="primary">
                <InfoOutlinedIcon />
            </IconButton>
        </Link>
    );
};

class SchoolContracts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: parseURLSearchParams(
                {
                    contractReferenceId: Types.STRING,
                    schoolyearName: Types.STRING,
                    schoolId: Types.STRING,
                    subjectId: Types.STRING,
                    distributorId: Types.STRING,
                    grade: Types.STRING,
                },
                props.history
            ),
        };
    }

    // we load the data automatically so we already have data when the user goes back from the contractbalances and contractspecifications screens
    componentDidMount() {
        this.reloadDataTable();
    }

    reloadDataTable() {
        this.setState({version: Number(Date.now())});
    }

    render() {
        const {classes, authUserReducer, history} = this.props;
        return (
            <Paper className={classes.root}>
                <SearchQuerySetter filters={this.state.filters} history={this.props.history} />
                <Typography component="h1" variant="h5" gutterBottom noWrap>
                    Schoolcontracten
                </Typography>
                <Grid container justify="flex-start" className={classes.inputContainer}>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <SearchTextField
                                fullWidth={true}
                                inputProps={{maxLength: '200'}}
                                label={'Contract id'}
                                value={this.state.filters['contractReferenceId']}
                                onEnter={() => this.reloadDataTable()}
                                onChange={(v) => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            contractReferenceId: v,
                                        },
                                    });
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.labelRoot,
                                    },
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <TextField
                            select
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            className={classes.formInputBox}
                            label="Schooljaar"
                            value={this.state.filters.schoolyearName}
                            onChange={(e) => {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        schoolyearName: e.target.value,
                                    },
                                });
                            }}
                        >
                            <MenuItem value={getSchoolYearName(1)}>{getSchoolYearName(1)}</MenuItem>
                            <MenuItem value={getSchoolYearName(0)}>{getSchoolYearName(0)}</MenuItem>
                            <MenuItem value={getSchoolYearName(-1)}>{getSchoolYearName(-1)}</MenuItem>
                            <MenuItem value="">Alles</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <AutoComplete
                                name={'location'}
                                endpoint={'organisations'}
                                endpointFilter={{type: 'School'}}
                                value={this.state.filters['schoolId']}
                                labelKey={'name'}
                                secondLabelKey={'esn'}
                                placeholder={'Schoollocatie...'}
                                onChange={(property, value) => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            ['schoolId']: value && value.id,
                                        },
                                    });
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <AutoComplete
                                name={'vak'}
                                endpoint={'products'}
                                endpointFilter={{isSubject: true}}
                                value={this.state.filters['subjectId']}
                                labelKey={'toString'}
                                secondLabelKey={'ean'}
                                valueKey={'id'}
                                placeholder={'Vak...'}
                                onChange={(property, value) => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            ['subjectId']: value && value.id,
                                        },
                                    });
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <AutoComplete
                                name={'distributor'}
                                endpoint={'organisations'}
                                endpointFilter={{type: 'Distributeur'}}
                                value={this.state.filters['distributorId']}
                                labelKey={'name'}
                                secondLabelKey={'id'}
                                placeholder={'Distributeur...'}
                                onChange={(property, value) => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            distributorId: value && value.id,
                                        },
                                    });
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <TextField
                            select
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            className={classes.formInputBox}
                            label="Leerjaar"
                            value={this.state.filters.grade}
                            onChange={(e) => {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        grade: e.target.value,
                                    },
                                });
                            }}
                        >
                            <MenuItem value="1">1</MenuItem>
                            <MenuItem value="2">2</MenuItem>
                            <MenuItem value="3">3</MenuItem>
                            <MenuItem value="4">4</MenuItem>
                            <MenuItem value="5">5</MenuItem>
                            <MenuItem value="6">6</MenuItem>
                            <MenuItem value="7">7</MenuItem>
                            <MenuItem value="8">8</MenuItem>
                            <MenuItem value="">Alles</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item>
                        <SearchButton onClick={this.reloadDataTable.bind(this)} />
                    </Grid>
                </Grid>
                <Grid container>
                    <DataTable
                        csv
                        endpoint="schoolcontracts"
                        version={this.state.version}
                        filter={this.state.filters}
                        columns={[
                            {label: 'Contract id', dataKey: 'contractReferenceId', maxWidth: 'auto'},
                            {label: 'Schooljaar', dataKey: 'schoolyearName', maxWidth: 'auto'},
                            {label: 'Schoollocatie', dataKey: 'schoolName', maxWidth: 'auto', minWidth: '200px'},
                            {label: 'Vak product', dataKey: 'subjectTitle', maxWidth: 'auto', minWidth: '100px'},
                            {label: 'DigideliveryId', dataKey: 'schoolEsn', maxWidth: 'auto'},
                            {label: 'Vak EAN', dataKey: 'subjectEan', minWidth: '130px', styling: {minWidth: '130px'}},
                            {label: 'Distributeur', dataKey: 'distributorName', maxWidth: 'auto'},
                            {
                                label: 'Leerjaren',
                                dataKey: 'grades',
                                maxWidth: 'auto',
                                component: <InfoResolveRenderer infoResolver={_formatGrades} />,
                            },
                            {label: 'Aantal', dataKey: 'specifiedAmount', maxWidth: '100px'},
                            {
                                label: 'Schoolbalans',
                                dataKey: 'ACTIONS',
                                component: <GoToContractBalance />,
                                styling: {justifyContent: 'center'},
                            },
                            {
                                label: 'Schoolspecs',
                                dataKey: 'ACTIONS',
                                component: <GoToContractSpecifications />,
                                styling: {justifyContent: 'center'},
                            },
                        ]}
                    />
                </Grid>
            </Paper>
        );
    }
}

const _formatGrades = (rowData) => {
    return {
        title: 'Leerjaar',
        rowValue: rowData.grades
            ?.split(',')
            .map((grade) => grade.replace(/JAAR_/g, ''))
            .sort()
            .join(', '),
    };
};

export default compose(
    defaultConnect,
    withPermissions(Recht.SCHOOLJAARVAKCONTRACTEN_INZIEN),
    withStyles(styles)
)(SchoolContracts);
