import {withStyles} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import classNames from 'classnames';
import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {compose} from 'recompose';

import {pageConfiguration} from '../../routes';
import defaultConnect from '../../store/connector';
import {hasRight} from '../permissions/withpermissions';

const styles = (theme) => {
    const drawerWidth = 240;
    return {
        drawerIcon: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0 8px',
            ...theme.mixins.toolbar,
        },
        drawerPaper: {
            minHeight: '100vh',
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            backgroundColor: '#00C2D1',
        },
        drawerPaperClose: {
            overflowX: 'hidden',
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
            },
        },
        drawerCloseIcon: {
            color: '#fff',
        },
        menuItem: {
            color: '#fff',
        },
        menuItemActive: {
            backgroundColor: '#00909B',
            color: '#fff',
            borderLeft: '5px solid #fff',
            transition: 'border-left 200ms ease',
        },
    };
};

function ToegangDrawer(props) {
    const {classes, authUserReducer} = props;

    const location = useLocation();

    return (
        <Drawer
            anchor="left"
            classes={{
                paper: classNames(classes.drawerPaper, !props.open && classes.drawerPaperClose),
            }}
            open={props.open}
            variant="permanent"
        >
            <div className={classes.drawerIcon}>
                <IconButton className={classes.drawerCloseIcon} onClick={props.handleDrawerClose}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <Divider />
            <MenuList>
                {pageConfiguration.map((p, index) => {
                    let hasAccess = true;
                    if (p.component.requiredRight) {
                        hasAccess = hasRight(authUserReducer, p.component.requiredRight);
                    }
                    const path = p.path.split('/')[1];
                    const activePath = location.pathname.split('/')[1];
                    let isActive = false;

                    if (path.length === activePath.length && path.includes(activePath)) {
                        isActive = true;
                    }

                    let menuClassNames = classNames(isActive ? classes.menuItemActive : classes.menuItem);
                    return (
                        hasAccess &&
                        !p.excludeFromSideBar && (
                            <Link to={p.path} style={{textDecoration: 'none', color: 'inherit'}} key={'menulink' + index}>
                                <MenuItem className={menuClassNames}>
                                    <ListItemIcon>{p.icon}</ListItemIcon>
                                    <ListItemText primary={p.title} />
                                </MenuItem>
                            </Link>
                        )
                    );
                })}
            </MenuList>
        </Drawer>
    );
}

export default compose(defaultConnect, withStyles(styles))(ToegangDrawer);
