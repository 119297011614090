import {Icon, Input} from '@material-ui/core';
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {hasRight} from "../permissions/withpermissions";
import {Recht} from "shared-types";

const styles = theme => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    edit: {
        width: '4rem',
        marginRight: '.5rem',
    },
    display: {
        marginRight: '.5rem',
    },
    submit: {
        display: 'none',
    },
    icon: {
        display: 'block',
    }
});

class EditableInfoResolveRenderer extends React.Component {

    constructor(props) {
        super(props)

        const {rowData, infoResolver} = this.props;
        const {rowValue} = infoResolver && infoResolver(rowData);

        this.state = {
            showForm: false,
            editValue: rowValue
        };

        this.form = React.createRef();
    }

    handleChange = (event) => {
        this.setState({editValue: event.target.value});
    }

    handleOnClickEdit = (event) => {
        this.setState({showForm: true});
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.doSubmit();
        }
    }
    handleOnClickCancel = (event) => {
        this.setState({showForm: false});
    }

    onFormSubmit = (event) => {
        event.preventDefault();
        this.doSubmit();
    }

    doSubmit = () => {
        const {onSave} = this.props;
        if (this.form.current.reportValidity()) {
            this.setState({showForm: false});
            onSave(this.props, this.state.editValue);
        }
    }

    createInput(title, rowValue, classes, inputProps, isEditable) {
        if (isEditable) {
            if (this.state.showForm) {
                return (
                    <form ref={this.form} onSubmit={this.onFormSubmit} className={classes.wrapper}>
                        <Input className={classes.edit} inputProps={inputProps} value={this.state.editValue}
                               onChange={this.handleChange} onKeyPress={this.handleKeyPress} autoFocus></Input>
                        <Input className={classes.submit} type='submit' id='save-button'/>
                        <label htmlFor='save-button'>
                            <Icon className={classes.icon}>save</Icon>
                        </label>
                        <Icon onClick={this.handleOnClickCancel}>cancel</Icon>
                    </form>
                )
            }

            return (
                <span className={classes.wrapper}>
                    <span className={classes.display} title={title}>{rowValue}</span>
                    <Icon onClick={this.handleOnClickEdit}>edit</Icon>
                </span>
            )
        }

        return <span className={classes.display} title={title}>{rowValue}</span>;
    }

    render() {
        const {classes, rowData, infoResolver, inputProps, isEditable} = this.props;
        const {title, rowValue} = infoResolver && infoResolver(rowData);
        return this.createInput(title, rowValue, classes, inputProps, isEditable);
    }
}

export default withStyles(styles)(EditableInfoResolveRenderer);
