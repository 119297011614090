import {Grid, Paper, Typography, withStyles} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import {Link} from 'react-router-dom';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import DataTable from '../../components/datatable';
import {ActionLinks} from '../../components/datatable/actionlink';
import {withPermissions} from '../../components/permissions/withpermissions';
import defaultConnect from '../../store/connector';
import ResetOAuthSecret from './resetsecret';

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    fab: {
        margin: 0,
        top: 'auto',
        left: 'auto',
        bottom: theme.spacing(5),
        right: theme.spacing(5),
        position: 'fixed',
        color: '#fff'
    },
    grid: {
        margin: theme.spacing(2),
        flexWrap: 'wrap'
    },
    inputContainer: {
        margin: theme.spacing(2),
        minWidth: 250
    },
    gridItemWide: {
        margin: theme.spacing(2),
        minWidth: 350
    }
});

class OAuthClients extends React.Component {
    componentWillMount() {
        this.setState({
            q: '',
            type: 'OAuthClient'
        });
    }

    render() {
        const {classes} = this.props;
        return (
            <Paper className={classes.root}>
                <Typography gutterBottom component="h1" variant="h5">
                    OAuthClients
                </Typography>
                <Grid container>
                    <DataTable
                        endpoint="oauthclients"
                        version={Number(new Date())}
                        filter={{
                            q: this.state.q,
                            type: this.state.type
                        }}
                        columns={[
                            {label: 'Naam', dataKey: 'displayname'},
                            {label: 'ClientID', dataKey: 'clientid'},
                            {label: 'RedirectUri', dataKey: 'redirecturis'},
                            {
                                label: 'Beheer',
                                dataKey: 'ACTIONS',
                                disableSort: true,
                                component: <ActionLinks endpoint={'/oauthclients'} actions={['EDIT']} />,
                                styling: {justifyContent: 'center'}
                            },
                            {
                                label: '',
                                dataKey: 'HMM',
                                disableSort: true,
                                component: <ResetOAuthSecret />
                            }
                        ]}
                    />
                </Grid>
                <Fab color="secondary" aria-label="Add" className={classes.fab} component={Link} to="/oauthclients/new">
                    <AddIcon />
                </Fab>
            </Paper>
        );
    }
}

export default compose(defaultConnect, withPermissions(Recht.OAUTHCLIENTS_TOEGANG), withStyles(styles))(OAuthClients);
