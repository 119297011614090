import {Button, Card, CardActions, CardContent, InputAdornment, Link, TextField, Typography} from '@material-ui/core';
import {SearchRounded} from '@material-ui/icons';
import React from 'react';
import {toast} from 'react-toastify';

import {fetchData, postData} from '../../services/restclient';
import AutoComplete from '../autocomplete';
import {toastPromise, toastWarn} from '../toast';

function PseudoIdTool(props) {
    const {title, description, buttonValue, subjectPlaceholder, httpMethod, endpoint, history, defaultSubject} = props;

    const [subject, setSubject] = React.useState('');
    const [publisher, setPublisher] = React.useState(null);

    React.useEffect(() => {
        if (defaultSubject) {
            setSubject(defaultSubject);
        }
    }, [defaultSubject]);

    const onButtonClick = React.useCallback(async () => {
        if (!subject) {
            /* op /accounts:id is deze altijd gevuld. Deze warning is voor de
             * pseudoID tool op /beheer waar je het account nog moet invullen. */
            toastWarn('Kies eerst een gebruiker.');
        }

        if (!publisher) {
            toastWarn('Kies eerst een uitgever.');
        }

        if (subject && publisher) {
            const finalEndpoint = endpoint({
                subject,
                publisher,
            });
            let request = null;
            if (httpMethod === 'POST') {
                request = postData({
                    endpoint: finalEndpoint,
                    entity: {
                        subject,
                        publisher,
                    },
                });
            } else if (httpMethod === 'GET') {
                request = fetchData({endpoint: finalEndpoint});
            } else {
                throw new Error('Geen httpMethod meegegeven.');
            }

            toastPromise(
                request,
                'Pseudoid decrypten',
                (res) => {
                    if (res.id) {
                        history.push(`/accounts/${res.id}/extended-view`);
                        toast.dismiss();
                    } else {
                        return (
                            <div>
                                <span>{`${res.data /* id voor decrypt, data voor encrypt */}`}</span>
                                <Link
                                    className={'toastButton'}
                                    component={Button}
                                    href="#"
                                    onClick={function (e) {
                                        e.preventDefault();
                                        navigator.clipboard.writeText(res.data);
                                        toast.dismiss();
                                    }}
                                >
                                    Kopieer en sluit
                                </Link>
                            </div>
                        );
                    }
                },
                (err) => {
                    if (err?.status === 404) {
                        return 'Er bestaat geen account voor dit pseudoid';
                    } else {
                        return `Er ging iets fout: ${err.message}`;
                    }
                },
                {autoClose: false, closeButton: true}
            );
        }
    }, [subject, publisher, endpoint]);

    const onDialogClose = React.useCallback(() => {
        if (!defaultSubject) {
            setSubject('');
        }
        setPublisher(null);
    });

    return (
        <React.Fragment>
            <Card style={{overflow: 'visible'}}>
                <CardContent>
                    <Typography gutterBottom component="h1" variant="h6">
                        {title}
                    </Typography>
                    <Typography variant="body2" component="p">
                        {description}
                    </Typography>
                    {!defaultSubject && (
                        <React.Fragment>
                            <TextField
                                variant={'outlined'}
                                fullWidth
                                style={{margin: '20px 0 10px 0'}}
                                placeholder={subjectPlaceholder}
                                value={subject}
                                onChange={(e) => {
                                    setSubject(e.target.value);
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchRounded style={{opacity: 0.2}} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </React.Fragment>
                    )}
                    <AutoComplete
                        name="uitgever"
                        endpoint="organisations"
                        endpointFilter={{type: 'Uitgever'}}
                        labelKey={'name'}
                        valueKey={'id'}
                        value={publisher}
                        placeholder="Kies uitgever..."
                        onChange={(property, value) => {
                            setPublisher(value);
                        }}
                    />
                </CardContent>
                <CardActions style={{justifyContent: 'flex-end'}}>
                    <Button color="primary" variant="contained" style={{color: '#fff'}} onClick={onButtonClick}>
                        {buttonValue}
                    </Button>
                </CardActions>
            </Card>
        </React.Fragment>
    );
}

export default PseudoIdTool;
