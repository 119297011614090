/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';

function toUrl(input) {
    if (input && !input.includes('//')) {
        return 'http://' + input;
    } else {
        return input;
    }
}

function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

export default function getValidationSchema() {
    return Yup.object()
        .shape({
            title: Yup.string()
                .nullable()
                .required(),
            ean: Yup.string()
                .required()
                .test('ean', 'Ongeldige EAN', function(ean) {
                    return ean.match(/^[1-9]{1}\d{12}$/);
                }),
            subproductDigitaal: Yup.string()
                .nullable()
                .test('subproductDigitaal', 'Ongeldige EAN', function(subproductDigitaal) {
                    return subproductDigitaal === null || subproductDigitaal.match(/^[1-9]{1}\d{12}$/);
                }),
            subproductFolio: Yup.string()
                .nullable()
                .test('subproductFolio', 'Ongeldige EAN', function(subproductFolio) {
                    return subproductFolio === null || subproductFolio.match(/^[1-9]{1}\d{12}$/);
                }),
            productType: Yup.string()
                .required('productType is verplicht')
                .max(255)
                .nullable(),
            organisation: Yup.mixed()
                .nullable()
                .required(),
            intendedEndUserRole: Yup.string()
                .nullable()
                .required(),
            ssoUrl: Yup.string()
                .transform(toUrl)
                .max(255)
                .url()
                .nullable()
                .when('productType', {
                    is: 'WEB_BROWSER',
                    then: Yup.string().required()
                }),
            introductionDate: Yup.date()
                .nullable()
                .required(),
            endOfLifeDate: Yup.date()
                .nullable()
                .required(),
            activationToggleEndDate: Yup.date()
                .nullable()
                .required(),
            browsers: Yup.array().when('productType', {
                is: 'WEB_BROWSER',
                then: Yup.array().required()
            }),
            platforms: Yup.array(),
            devices: Yup.array(),
            customProperties: Yup.object().shape({
                edition: Yup.string()
                    .max(50)
                    .nullable(),
                productUrl: Yup.string()
                    .transform(toUrl)
                    .max(255)
                    .url()
                    .nullable(),
                productThumbnailLocation: Yup.string()
                    .transform(toUrl)
                    .max(255)
                    .url()
                    .required(),
                sectors: Yup.array().required(),
                productFamilyThumbnailLocation: Yup.string()
                    .max(255)
                    .nullable(),
                contentLocation: Yup.string()
                    .transform(toUrl)
                    .max(255)
                    .url()
                    .nullable(),
                version: Yup.string()
                    .max(50)
                    .nullable(),
                informationLocation: Yup.string()
                    .transform(toUrl)
                    .max(255)
                    .url()
                    .nullable(),
                saleUnitSize: Yup.number()
                    .min(0)
                    .required(),
                description: Yup.string().nullable(),
                prices: Yup.array()
                    .of(
                        Yup.object().shape({
                            vat: Yup.number()
                                .min(-999.99)
                                .max(999.99)
                                .required(),
                            amount: Yup.number()
                                .min(0)
                                .integer()
                                .required()
                        })
                    )
                    .min(1, 'Product moet minimaal 1 prijs hebben')
                    .test('is-unique-vats', 'Product bevat meerdere prijzen met hetzelfde BTW percentage', prices => {
                        const distinctVats = [...new Set(prices.map(p => p.vat))];
                        return distinctVats.length === prices.length;
                    }),
                consumerPrice: Yup.number()
                    .min(0)
                    .integer()
                    .required()
            })
        })
        .test({
            test: function(product) {
                if (product.chains.includes('ECK')) {
                    if (!product.customProperties.authors || !product.customProperties.authors[0]) {
                        return this.createError({
                            path: 'customProperties.authors',
                            message: 'auteurs is verplicht'
                        });
                    }
                    if (!product.customProperties.courses || !product.customProperties.courses[0]) {
                        return this.createError({
                            path: 'customProperties.courses',
                            message: 'vakken is verplicht'
                        });
                    }

                    if (!product.customProperties.levels || !product.customProperties.levels[0]) {
                        return this.createError({
                            path: 'customProperties.levels',
                            message: 'niveaus is verplicht'
                        });
                    }
                }

                if (product.chains.includes('BASISPOORT')) {
                    if (!product.customProperties.productFamilyName) {
                        return this.createError({
                            path: 'customProperties.productFamilyName',
                            message: 'Methodenaam is verplicht'
                        });

                    }
                    if (!product.customProperties.productFamilyCode) {
                        return this.createError({
                            path: 'customProperties.productFamilyCode',
                            message: 'Methodecode is verplicht'
                        });
                    }
                }
                if (product.customProperties.productFamilyCode && !product.customProperties.productFamilyName) {
                    return this.createError({
                        path: 'customProperties.productFamilyName',
                        message: 'Methodenaam is verplicht als Methodecode code is ingevuld'
                    });

                }
                if (!product.customProperties.productFamilyCode && product.customProperties.productFamilyName) {
                    return this.createError({
                        path: 'customProperties.productFamilyCode',
                        message: 'Methodecode is verplicht als Methodenaam is ingevuld'
                    });

                }

                if (product.productType === 'WEB_BROWSER') {
                    const licenseType = product.licenseModel?.licenseType;
                    if (!licenseType) {
                        return this.createError({
                            path: 'licenseModel.licenseType',
                            message: 'Licentietype is verplicht'
                        });
                    }

                    switch (licenseType) {
                        case 'AANTAL':
                            const maximumLoginAmount = product.licenseModel.maximumLoginAmount;
                            if (!maximumLoginAmount) {
                                return this.createError({
                                    path: 'licenseModel.maximumLoginAmount',
                                    message: 'Aantal is verplicht.'
                                });
                            } else if (!Number(maximumLoginAmount)) {
                                return this.createError({
                                    path: 'licenseModel.maximumLoginAmount',
                                    message: 'Aantal moet uit cijfers bestaan'
                                });
                            }
                            break;
                        case 'VASTE_PERIODE':
                        case 'ABONNEMENT':
                            const duration = product.licenseModel.duration;
                            if (!duration) {
                                return this.createError({
                                    path: 'licenseModel.duration',
                                    message: 'Aantal dagen is verplicht'
                                });
                            } else if (!Number(duration)) {
                                return this.createError({
                                    path: 'licenseModel.duration',
                                    message: 'Aantal dagen moet uit cijfers bestaan'
                                });
                            }
                            break;
                        case 'SCHOOLJAARLICENTIE':
                            const schoolYearAmount = product.licenseModel.schoolYearAmount;
                            if (!schoolYearAmount) {
                                return this.createError({
                                    path: 'licenseModel.schoolYearAmount',
                                    message: 'Aantal schooljaren is verplicht'
                                });
                            } else if (isNaN(schoolYearAmount)) {
                                return this.createError({
                                    path: 'licenseModel.schoolYearAmount',
                                    message: 'Aantal schooljaren moet uit cijfers bestaan'
                                });
                            } else if (schoolYearAmount < 1) {
                                return this.createError({
                                    path: 'licenseModel.schoolYearAmount',
                                    message: 'Aantal schooljaren mag niet kleiner zijn dan 1'
                                });
                            }
                            if (!product.licenseModel.tiltDate) {
                                return this.createError({
                                    path: 'licenseModel.tiltDate',
                                    message: 'Kanteldatum is verplicht'
                                });
                            }
                            if (!product.licenseModel.schoolYearEndDate) {
                                return this.createError({
                                    path: 'licenseModel.schoolYearEndDate',
                                    message: 'Einde schooljaar voor dit product is verplicht'
                                });
                            }
                            break;
                        case 'VASTE_DATUM':
                            const licenseEndDate = product.licenseModel.endDate;
                            if (!licenseEndDate) {
                                return this.createError({
                                    path: 'licenseModel.endDate',
                                    message: 'Licentie einddatum is verplicht'
                                });
                            } else if (!isValidDate(licenseEndDate)) {
                                return this.createError({
                                    path: 'licenseModel.endDate',
                                    message: 'Ongeldige datum'
                                });
                            } else if (licenseEndDate < product.introductionDate) {
                                return this.createError({
                                    path: 'licenseModel.endDate',
                                    message: 'Datum mag niet voor introductiedatum liggen'
                                });
                            } else if (licenseEndDate > product.endOfLifeDate) {
                                return this.createError({
                                    path: 'licenseModel.endDate',
                                    message: 'Datum mag niet na end of life datum liggen'
                                });
                            } else if (product.licenseModel.licenseActivationType === 'PERIODE') {
                                const activatableFrom = product.licenseModel.activatableFrom;

                                if (licenseEndDate < activatableFrom) {
                                    return this.createError({
                                        path: 'licenseModel.endDate',
                                        message: 'Datum mag niet voor de activeren vanaf datum liggen'
                                    });
                                }
                            }
                            break;
                    }
                }

                if (product.licenseModel.licenseActivationType === 'PERIODE') {
                    let activatableFrom = new Date(product.licenseModel.activatableFrom);
                    let activatableUntil = new Date(product.licenseModel.activatableUntil);

                    if (!product.licenseModel.activatableFrom) {
                        return this.createError({
                            path: 'activatableFrom',
                            message: 'Datum is verplicht'
                        });
                    } else if (!product.licenseModel.activatableUntil) {
                        return this.createError({
                            path: 'activatableUntil',
                            message: 'Datum is verplicht'
                        });
                    } else if (!isValidDate(activatableFrom)) {
                        return this.createError({
                            path: 'activatableFrom',
                            message: 'Moet een valide datum zijn'
                        });
                    } else if (!isValidDate(activatableUntil)) {
                        return this.createError({
                            path: 'activatableUntil',
                            message: 'Moet een valide datum zijn'
                        });
                    } else if (activatableFrom < product.introductionDate) {
                        return this.createError({
                            path: 'activatableFrom',
                            message: 'Datum mag niet voor introductiedatum liggen'
                        });
                    } else if (activatableUntil > product.endOfLifeDate) {
                        return this.createError({
                            path: 'activatableUntil',
                            message: 'Datum mag niet na de end of life datum liggen'
                        });
                    } else if (activatableUntil < activatableFrom) {
                        return this.createError({
                            path: 'activatableUntil',
                            message: '"Te activeren tot" moet na "Te activeren vanaf" zijn'
                        });
                    } else {
                        return true;
                    }
                }

                if (product.licenseModel.licenseActivationType === 'AANTAL_DAGEN') {
                    if (!product.licenseModel.activatableAmountOfDays) {
                        return this.createError({
                            path: 'licenseModel.activatableAmountOfDays',
                            message: 'Er moet een aantal dagen ingevuld worden.'
                        });
                    } else if (isNaN(product.licenseModel.activatableAmountOfDays)) {
                        return this.createError({
                            path: 'licenseModel.activatableAmountOfDays',
                            message: 'Moet een nummer zijn.'
                        });
                    }
                }
                return true;
            }
        });
}
