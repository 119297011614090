import {Grid, Input, Paper, Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import {withStyles} from '@material-ui/core/styles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import {format} from 'date-fns';
import React, {useCallback, useEffect, useReducer, useState} from 'react';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import DataTable from '../../../components/datatable';
import {ActionLinks} from '../../../components/datatable/actionlink';
import {BooleanRenderer} from '../../../components/datatable/contentrenderers';
import GenericField from '../../../components/input/genericfield';
import ReadOnly from '../../../components/input/readonly';
import OrganisationStyleSelect from '../../../components/organisationstyle';
import {hasRight} from '../../../components/permissions/withpermissions';
import SimpleTable from '../../../components/simpletable';
import {toastError, toastPromise} from '../../../components/toast';
import entityReducer, {initialState} from '../../../reducers/entityreducer';
import {convertArrayToCSV} from '../../../services/util';
import * as ActionCreators from '../../../store/actioncreators';
import {POST_DATA_SUCCESS} from '../../../store/actions';
import autodispatch from '../../../store/autodispatch';
import defaultConnect from '../../../store/connector';

const informationText = 'Kijk voor informatie over de licentieduur op: https://www.thiememeulenhoff.nl/licentieduur.';

const styles = (theme) => ({
    root: {
        padding: theme.spacing(4),
        margin: theme.spacing(1),
    },
    formControl: {
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    fab: {
        margin: 0,
        top: 'auto',
        left: 'auto',
        zIndex: 999,
        bottom: theme.spacing(5),
        right: theme.spacing(5),
        position: 'fixed',
        color: '#fff',
    },
    grid: {
        flexWrap: 'wrap',
        '& > div > div': {
            width: '100%',
        },
    },
    inputContainer: {
        margin: theme.spacing(2),
        minWidth: 250,
    },
    gridItemWide: {
        margin: theme.spacing(2),
        minWidth: 350,
    },
    simpleTable: {
        minWidth: 250,
    },
    scrollable: {
        overflowY: 'scroll',
        maxHeight: '25em',
    },
    refreshButton: {
        textAlign: 'right',
        marginBottom: theme.spacing(2),
    },
    smallInput: {
        maxWidth: '5em',
    },
    colorWhite: {
        color: '#fff',
    },
});

function OrganisatieExtendedView(props) {
    const {postData, classes, match, authUserReducer} = props;

    const [showSimpleTLinkTable, setShowSimpleTLinkTable] = useState(false);
    const [version, setVersion] = useState(Date.now());
    const toggleTLinkTable = useCallback(() => {
        setShowSimpleTLinkTable(!showSimpleTLinkTable);
    }, [showSimpleTLinkTable]);
    const [state, dispatch] = useReducer(entityReducer, initialState);
    const {entity, loading} = state;
    const {fetchItem} = autodispatch(ActionCreators, dispatch);

    const amountPerProduct = {};

    useEffect(() => {
        const {id} = match.params;
        fetchItem({endpoint: 'organisations', id});
    }, [match]);

    const handleChangeAmount = (e, row) => {
        const value = parseInt(e.target.value);
        if (value > row.amount) {
            e.target.value = row.amount;
        } else if (value < 0) {
            e.target.value = '0';
        }
        amountPerProduct[row.productId] = e.target.value;
    };

    function getSimpleEckLicenseTable(authUserReducer) {
        return (
            <SimpleTable
                key={'simpleEckTable'}
                endpoint={`ecklicenses/summaryOfAvailableEckLicenses/${entity.id}`}
                version={version}
                styleBody={{'overflow-y': 'scroll', 'max-height': '25em'}}
                columns={[
                    {
                        header: 'Product',
                        linksTo: hasRight(authUserReducer, Recht.PRODUCTEN_INZIEN)
                            ? (row) => `/producten/${row.productId}/extended-view`
                            : undefined,
                        renderer: (row) => row.productTitle,
                    },
                    {
                        header: 'EAN',
                        renderer: (row) => row.productEan,
                    },
                    {
                        header: 'Aantal beschikbaar',
                        renderer: (row) => row.amount,
                    },
                    {
                        header: 'Aantal reserveren',
                        renderer: (row) => {
                            amountPerProduct[row.productId] = 0;
                            return (
                                <Input
                                    defaultValue={amountPerProduct[row.productId]}
                                    className={classes.smallInput}
                                    inputProps={{maxLength: 5}}
                                    type={'number'}
                                    onChange={(e) => {
                                        handleChangeAmount(e, row);
                                    }}
                                />
                            );
                        },
                    },
                    {
                        header: 'Reserveer & download codes',
                        style: {textAlign: 'center'},
                        renderer: (row) => (
                            <Button color="primary" onClick={() => handleTLinksReservation([row.productId])}>
                                <span className="material-icons">get_app</span>
                            </Button>
                        ),
                    },
                ]}
            />
        );
    }

    function handleTLinksReservation(productIds) {
        const entities = [];
        for (const productId of productIds) {
            entities.push({
                organisationId: entity.id,
                productId: productId,
                amount: amountPerProduct[productId],
            });
        }

        const reservingTLinks = postData({
            endpoint: 'tlinklicenses/actions/reserveTLinks',
            entity: entities,
        }).then((r) => {
            if (r.type === 'POST_DATA_SUCCESS') {
                setVersion(Date.now());
                const licenseCodes = [];

                licenseCodes.push(
                    ...r.data?.map((tLink) => {
                        const startDate = tLink.startDate ? format(new Date(tLink.startDate), 'dd-MM-yyyy') : '';
                        const endDate = tLink.endDate ? format(new Date(tLink.endDate), 'dd-MM-yyyy') : '';
                        return {
                            'Gereserveerde codes': tLink.licenseCode,
                            EAN: tLink.product.ean,
                            Titel: tLink.product.title,
                            'Inwisselbaar van': startDate,
                            'Inwisselbaar tot': endDate,
                            Informatie: informationText,
                        };
                    })
                );
                exportTLinkCodes(licenseCodes);
            }
            return r;
        });

        toastPromise(
            reservingTLinks,
            'De TLinks worden gereserveerd...',
            (r) => {
                if (r.type === 'POST_DATA_SUCCESS') {
                    return `De TLinks zijn gereserveerd.`;
                }
                toast.dismiss();
                toastError('De TLinks konden niet worden gereserveerd.');
            },
            (e) => `De TLinks konden niet worden gereserveerd. ${e.error.message || e.message || ''}`
        );
    }

    function exportTLinkCodes(reservedTLinks) {
        const csvContent =
            'data:text/csv;charset=utf-8,' +
            convertArrayToCSV(reservedTLinks, [
                'Gereserveerde codes',
                'EAN',
                'Titel',
                'Inwisselbaar van',
                'Inwisselbaar tot',
                'Informatie',
            ]);

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');

        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `Activeringscodes-${new Date().getTime()}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);

        link.click();
    }

    function getSimpleTLinkTable(authUserReducer) {
        return (
            <SimpleTable
                key={'simpleTLinkTable'}
                endpoint="tlinklicenses"
                sortBy="licensecode"
                sortDirection="ASC"
                version={version}
                filter={{
                    organisation: !entity.type.includes('Uitgever') ? entity.id : undefined,
                    'product.organisation': entity.type.includes('Uitgever') ? entity.id : undefined,
                }}
                columns={[
                    {
                        header: 'Code',
                        linksTo: hasRight(authUserReducer, Recht.TLINKLICENTIES_AANPASSEN)
                            ? (row) => `/tlinks/${row.id}/edit`
                            : undefined,
                        renderer: (row) => row.licenseCode,
                    },
                    {
                        header: 'Product',
                        linksTo: hasRight(authUserReducer, Recht.PRODUCTEN_INZIEN)
                            ? (row) => `/producten/${row.product.id}/extended-view`
                            : undefined,
                        renderer: (row) => row.product.title + ' (' + row.product.ean + ')',
                    },
                    {
                        header: 'Status',
                        renderer: (row) => row.licenseState,
                    },
                    {
                        header: 'Account',
                        linksTo: hasRight(authUserReducer, Recht.GEBRUIKERS_INZIEN)
                            ? (row) => (row.account ? `/accounts/${row.account.id}/extended-view` : undefined)
                            : undefined,
                        renderer: (row) => (row.account ? row.account.toString : undefined),
                    },
                ]}
            />
        );
    }

    function getSummaryTLinkTable(authUserReducer) {
        return (
            <SimpleTable
                key={'summaryTLinkTable'}
                endpoint="tlinklicenses/productsummary"
                sortBy="licensecode"
                version={version}
                sortDirection="ASC"
                filter={{
                    organisation: !entity.type.includes('Uitgever') ? entity.id : undefined,
                    'product.organisation': entity.type.includes('Uitgever') ? entity.id : undefined,
                }}
                columns={[
                    {
                        header: 'Product',
                        linksTo: hasRight(authUserReducer, Recht.PRODUCTEN_INZIEN)
                            ? (row) => `/producten/${row.product.id}/extended-view`
                            : undefined,
                        renderer: (row) => row.product.title + ' (' + row.product.ean + ')',
                    },
                    {
                        header: 'Status',
                        renderer: (row) => row.licenseState,
                    },
                    {
                        header: 'Aantal',
                        renderer: (row) => row.count,
                    },
                ]}
                className={classes.simpleTable}
            />
        );
    }

    return entity && !loading ? (
        <React.Fragment>
            <Paper className={classes.root}>
                <Grid container className={classes.grid}>
                    <Grid item sm={12} md={12}>
                        <Typography component="h1" variant="h5" style={{marginTop: '40'}} gutterBottom noWrap>
                            Overzicht {entity ? entity.toString : ''}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <GenericField label="Naam">
                            <ReadOnly>{entity.name || '-'}</ReadOnly>
                        </GenericField>
                        <br />
                        <GenericField label="digiDeliveryId">
                            {entity.isAfkomstigUitCr ? (
                                <ReadOnly>
                                    <b>{entity.esn || '-'}</b>
                                </ReadOnly>
                            ) : (
                                <ReadOnly>{entity.esn || '-'}</ReadOnly>
                            )}
                        </GenericField>
                        <br />
                        <GenericField label="BRIN">
                            <ReadOnly>{entity.brin || '-'}</ReadOnly>
                        </GenericField>
                        <br />
                        <GenericField label="Sector">
                            <ReadOnly>{entity.sector || '-'}</ReadOnly>
                        </GenericField>
                        <GenericField label="Volledige naam">
                            <ReadOnly>{entity.askForVolledigeNaam ? 'Ja' : 'Nee'}</ReadOnly>
                        </GenericField>
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <GenericField label="Type">
                            <ReadOnly>{entity.type && entity.type.length ? entity.type.join(', ') : '-'}</ReadOnly>
                        </GenericField>
                        <br />
                        <GenericField label="InstellingBRIN">
                            <ReadOnly>{entity.instellingBrin || '-'}</ReadOnly>
                        </GenericField>
                        <br />
                        <GenericField label="Stad">
                            <ReadOnly>{entity.city || '-'}</ReadOnly>
                        </GenericField>
                        <br />
                        <GenericField label="PortalId/Realm">
                            <ReadOnly>{entity.portalId || '-'}</ReadOnly>
                        </GenericField>
                        <br />
                        <GenericField label="Afkomstig uit CR">
                            <ReadOnly>{entity.isAfkomstigUitCr ? 'Ja' : 'Nee'}</ReadOnly>
                        </GenericField>
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <GenericField label="Extern ID">
                            <ReadOnly>{<i>{entity.externId}</i> || '-'}</ReadOnly>
                        </GenericField>
                        <br />
                        <GenericField label="Website">
                            <ReadOnly>{entity.websiteUrl || '-'}</ReadOnly>
                        </GenericField>
                        <br />
                        <GenericField label="Abonnementen url">
                            <ReadOnly>{entity.abonnementUrl || '-'}</ReadOnly>
                        </GenericField>
                        <br />
                        <GenericField label="Geverifieerd">
                            <ReadOnly>{entity.verified ? 'Ja' : 'Nee'}</ReadOnly>
                        </GenericField>
                        <br />
                        <GenericField label="ARP">
                            <ReadOnly>{entity.arp ? 'Ja' : 'Nee'}</ReadOnly>
                        </GenericField>
                        {entity.restrictedTo && (
                            <GenericField label="Specifiek voor">
                                <ReadOnly>{entity.restrictedTo.name}</ReadOnly>
                            </GenericField>
                        )}
                    </Grid>
                </Grid>
                {entity.type.includes('Uitgever') && (
                    <Grid container>
                        <Grid item sm={12} md={12}>
                            <OrganisationStyleSelect organisation={entity.id} />
                        </Grid>
                    </Grid>
                )}
                {entity?.type.includes('School') && hasRight(authUserReducer, Recht.TLINKLICENTIES_RESERVEREN) ? (
                    <Grid container className={classes.grid}>
                        <Grid item sm={10} md={10}>
                            <Typography component="h1" variant="h5">
                                Tlinks reserveren
                            </Typography>
                        </Grid>
                        <Grid item sm={2} md={2} className={classes.refreshButton}>
                            <Button title="Verversen" color="primary" variant="contained" onClick={() => setVersion(new Date())}>
                                <RefreshIcon className={classes.colorWhite} />
                            </Button>
                            &nbsp;
                            <Button
                                title="Reserveer & download codes van alle te reserveren producten"
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    handleTLinksReservation(Object.keys(amountPerProduct));
                                }}
                            >
                                <CloudDownloadIcon className={classes.colorWhite} />
                            </Button>
                        </Grid>
                        <Grid item sm={12} md={12} className={classes.scrollable}>
                            {getSimpleEckLicenseTable(authUserReducer)}
                        </Grid>
                    </Grid>
                ) : (
                    ''
                )}
            </Paper>
            <Paper className={classes.root}>
                <Grid container className={classes.grid}>
                    <Grid item sm={12} md={12}>
                        <Typography component="h1" variant="h5">
                            TLinks
                        </Typography>
                    </Grid>
                    <Grid item sm={12} md={12} className={classes.button}>
                        <Button color="primary" variant="contained" className={classes.colorWhite} onClick={toggleTLinkTable}>
                            {showSimpleTLinkTable ? 'Toon samenvatting' : 'Toon alles'}
                        </Button>
                    </Grid>
                    <Grid item sm={12} md={12}>
                        {showSimpleTLinkTable ? getSimpleTLinkTable(authUserReducer) : getSummaryTLinkTable(authUserReducer)}
                    </Grid>
                </Grid>
            </Paper>
            <Paper className={classes.root}>
                <Grid container className={classes.grid}>
                    <Grid item sm={12} md={12}>
                        <Typography component="h1" variant="h5">
                            Gerelateerde organisaties
                        </Typography>
                    </Grid>
                    <Grid container>
                        <DataTable
                            endpoint={`organisations/${entity.id}/siblings`}
                            version={Number(new Date())}
                            columns={[
                                {
                                    label: '',
                                    dataKey: 'ACTIONS',
                                    width: 10,
                                    disableSort: true,
                                    component: <ActionLinks endpoint={'/organisaties'} actions={['EDIT', 'EXTENDEDVIEW']} />,
                                },
                                {label: 'Geverifieerd', dataKey: 'verified', component: <BooleanRenderer />},
                                {label: 'ESN', dataKey: 'esn'},
                                {label: 'Naam', dataKey: 'name'},
                                {label: 'Type', dataKey: 'type', disableSort: true},
                                {label: 'Brin', dataKey: 'brin', disableSort: true},
                                {label: 'Instelling Brin', dataKey: 'instellingBrin', disableSort: true},
                            ]}
                        />
                    </Grid>
                </Grid>
            </Paper>
            {hasRight(authUserReducer, Recht.ORGANISATIES_AANPASSEN) && (
                <Fab
                    color="secondary"
                    aria-label="Add"
                    className={classes.fab}
                    component={Link}
                    to={`/organisaties/${entity.id}/edit`}
                >
                    <EditIcon />
                </Fab>
            )}
        </React.Fragment>
    ) : (
        <React.Fragment>
            <CircularProgress />
        </React.Fragment>
    );
}

export default compose(defaultConnect, withStyles(styles))(OrganisatieExtendedView);
