import React from 'react';
import {Redirect} from 'react-router';
import {Recht} from 'shared-types';

import store from '../../store/store';

export function hasRight(authUserReducer, right) {
    return (
        authUserReducer &&
        authUserReducer.beheerder &&
        authUserReducer.beheerder.rol &&
        authUserReducer.beheerder.rol.rechten &&
        (authUserReducer.beheerder.rol.rechten.includes(right) ||
            authUserReducer.beheerder.rol.rechten.includes(Recht.IS_SUPERUSER))
    );
}

export function isUitgeverAccount(authUserReducer) {
    if (!authUserReducer?.beheerder || authUserReducer?.beheerder?.uitgever) {
        return true;
    }
    return false;
}

export const withPermissions = right => Component => {
    const hasPermissions = () => {
        const {authUserReducer} = store.getState();

        if (!authUserReducer.loading && authUserReducer.beheerder) {
            return authUserReducer.beheerder && hasRight(authUserReducer, right);
        } else {
            return true;
        }
    };

    const protectedComponent = class extends React.Component {
        render() {
            return hasPermissions() ? <Component {...this.props} /> : <Redirect to={'/access-denied'} />;
        }
    };
    protectedComponent.requiredRight = right;
    return protectedComponent;
};
