"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PoRole = exports.ProductSyncErrorCodes = exports.IdentityProvider = exports.BillingModelType = exports.ChainType = exports.EckRestTarget = exports.EndUserRoleType = exports.SectorType = exports.EducationalYearType = exports.ProductUsage = exports.ProductState = exports.DeviceType = exports.PlatformType = exports.ProductType = exports.BrowserType = exports.LicenseActivationType = exports.LicenseType = exports.Recht = void 0;
var Recht;
(function (Recht) {
  Recht["ACCESSURLS_VERWIJDEREN"] = "accessurls_verwijderen";
  Recht["ORGANISATIES_INZIEN"] = "organisaties_inzien";
  Recht["ORGANISATIES_TOEVOEGEN"] = "organisaties_toevoegen";
  Recht["ORGANISATIES_AANPASSEN"] = "organisaties_aanpassen";
  Recht["ORGANISATIES_VERWIJDEREN"] = "organisaties_verwijderen";
  Recht["ORGANISATIES_UITGEVERS_DISTRIBUTEURS_TOEGANG"] = "organisaties_uitgevers_distributeurs_toegang";
  Recht["OAUTHCLIENTS_TOEGANG"] = "oauthclients_toegang";
  Recht["PRODUCTEN_INZIEN"] = "producten_inzien";
  Recht["PRODUCTEN_TOEVOEGEN"] = "producten_toevoegen";
  Recht["PRODUCTEN_AANPASSEN"] = "producten_aanpassen";
  Recht["PRODUCTEN_VERWIJDEREN"] = "producten_verwijderen";
  Recht["PRODUCTEN_SERVICEWINDOWS_TOEGANG"] = "producten_servicewindows_toegang";
  Recht["PRODUCTEN_SUBPRODUCTEN_TOEGANG"] = "producten_subproducten_toegang";
  Recht["GEBRUIKERS_INZIEN"] = "gebruikers_inzien";
  Recht["GEBRUIKERS_TOEVOEGEN"] = "gebruikers_toevoegen";
  Recht["GEBRUIKERS_AANPASSEN"] = "gebruikers_aanpassen";
  Recht["GEBRUIKERS_SESSIES_AFMELDEN"] = "gebruikers_sessies_afmelden";
  Recht["GEBRUIKERS_SAMENVOEGEN"] = "gebruikers_samenvoegen";
  Recht["BEHEERDERS_INZIEN"] = "beheerders_inzien";
  Recht["BEHEERDERS_TOEVOEGEN"] = "beheerders_toevoegen";
  Recht["BEHEERDERS_AANPASSEN"] = "beheerders_aanpassen";
  Recht["BEHEERDERS_VERWIJDEREN"] = "beheerders_verwijderen";
  Recht["ROLLEN_INZIEN"] = "rollen_inzien";
  Recht["ROLLEN_TOEVOEGEN"] = "rollen_toevoegen";
  Recht["ROLLEN_AANPASSEN"] = "rollen_aanpassen";
  Recht["ROLLEN_VERWIJDEREN"] = "rollen_verwijderen";
  Recht["TLINKLICENTIES_INZIEN"] = "tlinklicenties_inzien";
  Recht["TLINKLICENTIES_TOEVOEGEN"] = "tlinklicenties_toevoegen";
  Recht["TLINKLICENTIES_AANPASSEN"] = "tlinklicenties_aanpassen";
  Recht["TLINKLICENTIES_RESERVEREN"] = "tlinklicenties_reserveren";
  Recht["TLINKLICENTIES_DESPECIFICEREN"] = "tlinklicenties_despecificeren";
  Recht["EXTERNACCOUNTS_TOEVOEGEN"] = "externaccounts_toevoegen";
  Recht["EXTERNACCOUNTS_VERWIJDEREN"] = "externaccounts_verwijderen";
  Recht["EXTERNACCOUNTS_KOPPELEN"] = "externaccounts_koppelen";
  Recht["ECKLICENTIES_INZIEN"] = "ecklicenties_inzien";
  Recht["SCHOOLJAARVAKCONTRACTEN_INZIEN"] = "schooljaarvakcontracten_inzien";
  Recht["SUPPORT_INZIEN"] = "support_inzien";
  Recht["SUPPORT_AFMELDEN"] = "support_afmelden";
  // Deze rechten zijn alleen voor thieme
  Recht["RETRY_PRIUS_ORDER"] = "retry_prius_order";
  Recht["PROFIELHUIS_ACCES"] = "profielhuis_acces";
  // Dit betekent toegang tot het scherm Beheer in de Beheer-UI, dus niet 'Beheerder van Toegang.org'.
  Recht["BEHEER_TOEGANG"] = "beheer_toegang";
  Recht["LOGENTRIES_INZIEN"] = "logentries_inzien";
  Recht["ORDERS_INZIEN"] = "orders_inzien";
  Recht["ORDERS_TOEVOEGEN"] = "orders_toevoegen";
  Recht["ORDERBALANS_INZIEN"] = "orderbalans_inzien";
  Recht["FACTUUR_INZIEN"] = "factuur_inzien";
  Recht["POLICENTIES_INZIEN"] = "policenties_inzien";
  Recht["POLICENTIES_VERWIJDEREN"] = "policenties_verwijderen";
  Recht["WASWORDT_IMPORTEREN"] = "waswordt_importeren";
  // LET OP! DIT RECHT NIET ZOMAAR GEVEN
  Recht["IS_SUPERUSER"] = "is_superuser";
})(Recht || (exports.Recht = Recht = {}));
var LicenseType;
(function (LicenseType) {
  LicenseType["VRIJE_LICENTIE"] = "VRIJE_LICENTIE";
  LicenseType["VASTE_DATUM"] = "VASTE_DATUM";
  LicenseType["VASTE_PERIODE"] = "VASTE_PERIODE";
  LicenseType["SCHOOLJAARLICENTIE"] = "SCHOOLJAARLICENTIE";
  LicenseType["AANTAL"] = "AANTAL";
  LicenseType["ABONNEMENT"] = "ABONNEMENT";
})(LicenseType || (exports.LicenseType = LicenseType = {}));
var LicenseActivationType;
(function (LicenseActivationType) {
  LicenseActivationType["PERIODE"] = "PERIODE";
  LicenseActivationType["EINDE_PRODUCT"] = "EINDE_PRODUCT";
  LicenseActivationType["KANTELDATUM"] = "KANTELDATUM";
  LicenseActivationType["AANTAL_DAGEN"] = "AANTAL_DAGEN";
})(LicenseActivationType || (exports.LicenseActivationType = LicenseActivationType = {}));
var BrowserType;
(function (BrowserType) {
  BrowserType["FIREFOX"] = "FIREFOX";
  BrowserType["CHROME"] = "CHROME";
  BrowserType["EDGE"] = "EDGE";
  BrowserType["SAFARI"] = "SAFARI";
  BrowserType["IE"] = "IE";
  BrowserType["OPERA"] = "OPERA";
  BrowserType["OVERIGE"] = "OVERIGE";
})(BrowserType || (exports.BrowserType = BrowserType = {}));
var ProductType;
(function (ProductType) {
  ProductType["WEB_BROWSER"] = "WEB_BROWSER";
  ProductType["DOWNLOADABLE_INSTALLER"] = "DOWNLOADABLE_INSTALLER";
  ProductType["BOEK"] = "BOEK";
  ProductType["USB"] = "USB";
  ProductType["CD_ROM"] = "CD_ROM";
  ProductType["DVD"] = "DVD";
  ProductType["ANDERS"] = "ANDERS";
})(ProductType || (exports.ProductType = ProductType = {}));
var PlatformType;
(function (PlatformType) {
  PlatformType["IOS"] = "IOS";
  PlatformType["ANDROID"] = "ANDROID";
  PlatformType["WINDOWS"] = "WINDOWS";
  PlatformType["HTML5"] = "HTML5";
})(PlatformType || (exports.PlatformType = PlatformType = {}));
var DeviceType;
(function (DeviceType) {
  DeviceType["PC_READY"] = "PC_READY";
  DeviceType["MOBILE_READY"] = "MOBILE_READY";
  DeviceType["TABLET_READY"] = "TABLET_READY";
})(DeviceType || (exports.DeviceType = DeviceType = {}));
var ProductState;
(function (ProductState) {
  ProductState["NOG_NIET_LEVERBAAR"] = "NOG_NIET_LEVERBAAR";
  ProductState["BEPERKT_LEVERBAAR"] = "BEPERKT_LEVERBAAR";
  ProductState["LEVERBAAR"] = "LEVERBAAR";
  ProductState["TIJDELIJK_NIET_LEVERBAAR"] = "TIJDELIJK_NIET_LEVERBAAR";
  ProductState["NIET_MEER_LEVERBAAR"] = "NIET_MEER_LEVERBAAR";
  ProductState["ZAL_NIET_VERSCHIJNEN"] = "ZAL_NIET_VERSCHIJNEN";
})(ProductState || (exports.ProductState = ProductState = {}));
var ProductUsage;
(function (ProductUsage) {
  ProductUsage["LEERBOEK"] = "LEERBOEK";
  ProductUsage["WERKBOEK"] = "WERKBOEK";
  ProductUsage["EXAMENBUNDEL"] = "EXAMENBUNDEL";
  ProductUsage["OEFENMATERIAAL"] = "OEFENMATERIAAL";
  ProductUsage["NASLAGWERK"] = "NASLAGWERK";
  ProductUsage["ANDERS"] = "ANDERS";
})(ProductUsage || (exports.ProductUsage = ProductUsage = {}));
var EducationalYearType;
(function (EducationalYearType) {
  EducationalYearType["JAAR_1"] = "JAAR_1";
  EducationalYearType["JAAR_2"] = "JAAR_2";
  EducationalYearType["JAAR_3"] = "JAAR_3";
  EducationalYearType["JAAR_4"] = "JAAR_4";
  EducationalYearType["JAAR_5"] = "JAAR_5";
  EducationalYearType["JAAR_6"] = "JAAR_6";
  EducationalYearType["JAAR_7"] = "JAAR_7";
  EducationalYearType["JAAR_8"] = "JAAR_8";
  EducationalYearType["ONDERBOUW"] = "ONDERBOUW";
  EducationalYearType["BOVENBOUW"] = "BOVENBOUW";
})(EducationalYearType || (exports.EducationalYearType = EducationalYearType = {}));
var SectorType;
(function (SectorType) {
  SectorType["PO"] = "PO";
  SectorType["VO"] = "VO";
  SectorType["MBO"] = "MBO";
  SectorType["HO"] = "HO";
  SectorType["WO"] = "WO";
})(SectorType || (exports.SectorType = SectorType = {}));
var EndUserRoleType;
(function (EndUserRoleType) {
  EndUserRoleType["ONDERWIJSGEVER"] = "Onderwijsgever";
  EndUserRoleType["ONDERWIJSVOLGER"] = "Onderwijsvolger";
  EndUserRoleType["BEHEERDER"] = "Beheerder";
})(EndUserRoleType || (exports.EndUserRoleType = EndUserRoleType = {}));
var EckRestTarget;
(function (EckRestTarget) {
  EckRestTarget["TOEGANG"] = "TOEGANG";
  EckRestTarget["THIEME"] = "THIEME";
})(EckRestTarget || (exports.EckRestTarget = EckRestTarget = {}));
var ChainType;
(function (ChainType) {
  ChainType["ECK"] = "ECK";
  ChainType["BASISPOORT"] = "BASISPOORT";
})(ChainType || (exports.ChainType = ChainType = {}));
var BillingModelType;
(function (BillingModelType) {
  BillingModelType["PERCENTAGE"] = "Percentage";
  BillingModelType["VASTE_PRIJS"] = "Vaste Prijs";
})(BillingModelType || (exports.BillingModelType = BillingModelType = {}));
var IdentityProvider;
(function (IdentityProvider) {
  IdentityProvider["KENNISNET_FEDERATIE"] = "KENNISNET_FEDERATIE";
  IdentityProvider["TOEGANG_ORG"] = "TOEGANG_ORG";
  IdentityProvider["BASISPOORT"] = "BASISPOORT";
})(IdentityProvider || (exports.IdentityProvider = IdentityProvider = {}));
var ProductSyncErrorCodes;
(function (ProductSyncErrorCodes) {
  ProductSyncErrorCodes[ProductSyncErrorCodes["SUCCESS"] = 0] = "SUCCESS";
  ProductSyncErrorCodes[ProductSyncErrorCodes["MISSING_PARAMETERS"] = 1] = "MISSING_PARAMETERS";
  ProductSyncErrorCodes[ProductSyncErrorCodes["FAILED"] = 2] = "FAILED";
  ProductSyncErrorCodes[ProductSyncErrorCodes["INVALID_VALUES"] = 3] = "INVALID_VALUES";
})(ProductSyncErrorCodes || (exports.ProductSyncErrorCodes = ProductSyncErrorCodes = {}));
var AuthenticationType;
(function (AuthenticationType) {
  AuthenticationType["TOEGANG_SSO"] = "TOEGANG_SSO";
  AuthenticationType["OAUTH2"] = "OAUTH2";
})(AuthenticationType || (AuthenticationType = {}));
var PoRole;
(function (PoRole) {
  PoRole["Student"] = "leerling";
  PoRole["Teacher"] = "leerkracht";
})(PoRole || (exports.PoRole = PoRole = {}));