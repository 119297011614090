import DateFnsUtils from '@date-io/date-fns';
import {
    Button,
    CardContent,
    Checkbox,
    Fab,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    withStyles,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteForever from '@material-ui/icons/DeleteForever';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SaveIcon from '@material-ui/icons/Save';
import StopIcon from '@material-ui/icons/Stop';
import {DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import classNames from 'classnames';
import {nl} from 'date-fns/locale';
import {Form, Formik} from 'formik';
import * as cloneDeep from 'lodash.clonedeep';
import * as get from 'lodash.get';
import * as remove from 'lodash.remove';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {compose} from 'recompose';
import {
    BrowserType,
    ChainType,
    DeviceType,
    EducationalYearType,
    EndUserRoleType,
    PlatformType,
    ProductUsage,
    Recht,
    SectorType,
} from 'shared-types';

import AutoComplete from '../../../components/autocomplete';
import ConfirmationDialog from '../../../components/confirmationdialog';
import GenericField from '../../../components/input/genericfield';
import ReadOnly from '../../../components/input/readonly';
import {hasRight} from '../../../components/permissions/withpermissions';
import {toastPromise} from '../../../components/toast';
import {mapStateToProps} from '../../../store/connector';
import getValidationSchema from './getValidationSchema';

const dateFns = new DateFnsUtils();
const endpoint = 'products';
const styles = (theme) => ({
    root: {
        padding: 0,
    },
    button: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    checkbox: {
        marginLeft: theme.spacing(1),
    },
    checkboxContainer: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    checkboxGrid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
    },
    error: {
        marginLeft: '2px',
        color: '#f00',
    },
    formControl: {
        marginBottom: theme.spacing(1),
    },
    gridContainer: {
        paddingRight: theme.spacing(2),
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    fullWidth: {
        width: '100%',
    },
    fab: {
        margin: 0,
        top: 'auto',
        left: 'auto',
        zIndex: 999,
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        bottom: theme.spacing(5),
        right: theme.spacing(5),

        '& button:not(:last-child)': {
            marginBottom: theme.spacing(1),
            position: 'absolute',
            transform: 'scale(0)',
            transitionDelay: '50ms',
            transition: 'transform cubic-bezier(0.94, 0.15, 1, 0.11) 300ms',
        },

        '&:hover button:not(:last-child)': {
            transition: 'transform ease 250ms',
            transform: 'scale(.85) translateY(-70px)',
        },
    },
    colorWhite: {
        color: '#fff',
    },
    actionButtonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    saveButton: {
        color: '#fff',
    },
    cancelButton: {
        backgroundColor: '#E0E0E0',
        color: '#fff',
    },
});

const _doSubmit = (values, syncData, endpoint) => {
    if (values.productType !== 'WEB_BROWSER') {
        values.browsers = [];
        values.serviceLicenseAllowed = false;
        values.ssoUrl = null;
        values.ecktarget = null;
    }
    const syncing = syncData({
        endpoint: endpoint,
        entity: getValidationSchema().cast(values),
    });

    return toastPromise(
        syncing,
        'Product wordt opgeslagen...',
        `Het product is opgeslagen.`,
        (e) =>
            `Er is iets misgegaan bij het opslaan van het product${
                e.message || e.error.message ? ': ' + (e.message ?? e.error.message) : ''
            }.`,
        null,
        true
    );
};

const browsersOptions = [
    {
        value: BrowserType[BrowserType.FIREFOX],
        label: 'FireFox',
    },
    {
        value: BrowserType[BrowserType.CHROME],
        label: 'Chrome',
    },
    {
        value: BrowserType[BrowserType.EDGE],
        label: 'Edge',
    },
    {
        value: BrowserType[BrowserType.SAFARI],
        label: 'Safari',
    },
    {
        value: BrowserType[BrowserType.IE],
        label: 'Internet Explorer',
    },
    {
        value: BrowserType[BrowserType.OPERA],
        label: 'Opera',
    },
    {
        value: BrowserType[BrowserType.OVERIGE],
        label: 'Overige browser',
    },
];

const sectorsOptions = [
    {
        value: SectorType[SectorType.PO],
    },
    {
        value: SectorType[SectorType.VO],
    },
    {
        value: SectorType[SectorType.MBO],
    },
    {
        value: SectorType[SectorType.HO],
    },
    {
        value: SectorType[SectorType.WO],
    },
];

const platformOptions = [
    {
        value: PlatformType[PlatformType.IOS],
    },
    {
        value: PlatformType[PlatformType.ANDROID],
    },
    {
        value: PlatformType[PlatformType.WINDOWS],
    },
    {
        value: PlatformType[PlatformType.HTML5],
    },
];

const deviceOptions = [
    {
        value: DeviceType[DeviceType.PC_READY],
        label: 'pc ready',
    },
    {
        value: DeviceType[DeviceType.MOBILE_READY],
        label: 'mobile ready',
    },
    {
        value: DeviceType[DeviceType.TABLET_READY],
        label: 'tablet ready',
    },
];

const productUsagesOptions = [
    {
        value: ProductUsage[ProductUsage.LEERBOEK],
        label: 'Leerboek',
    },
    {
        value: ProductUsage[ProductUsage.WERKBOEK],
        label: 'Werkboek',
    },
    {
        value: ProductUsage[ProductUsage.EXAMENBUNDEL],
        label: 'Examenbundel',
    },
    {
        value: ProductUsage[ProductUsage.OEFENMATERIAAL],
        label: 'Oefenmateriaal',
    },
    {
        value: ProductUsage[ProductUsage.NASLAGWERK],
        label: 'Naslagwerk',
    },
    {
        value: ProductUsage[ProductUsage.ANDERS],
        label: 'Anders',
    },
];

const yearOptions = [
    {
        value: EducationalYearType[EducationalYearType.JAAR_1],
        label: 'Jaar 1',
    },
    {
        value: EducationalYearType[EducationalYearType.JAAR_2],
        label: 'Jaar 2',
    },
    {
        value: EducationalYearType[EducationalYearType.JAAR_3],
        label: 'Jaar 3',
    },
    {
        value: EducationalYearType[EducationalYearType.JAAR_4],
        label: 'Jaar 4',
    },
    {
        value: EducationalYearType[EducationalYearType.JAAR_5],
        label: 'Jaar 5',
    },
    {
        value: EducationalYearType[EducationalYearType.JAAR_6],
        label: 'Jaar 6',
    },
    {
        value: EducationalYearType[EducationalYearType.JAAR_7],
        label: 'Jaar 7',
    },
    {
        value: EducationalYearType[EducationalYearType.JAAR_8],
        label: 'Jaar 8',
    },
    {
        value: EducationalYearType[EducationalYearType.ONDERBOUW],
        label: 'Onderbouw',
    },
    {
        value: EducationalYearType[EducationalYearType.BOVENBOUW],
        label: 'Bovenbouw',
    },
];

function ProductForm(props) {
    const {classes, entity, deleteItem, history, authUserReducer, postSuccess} = props;
    const [openDelete, setOpenDelete] = React.useState(false);
    const [openDeactivate, setOpenDeactivate] = React.useState(false);
    const [canSelectOrganisation, setCanSelectOrganisation] = React.useState(false);
    const [canDelete, setCanDelete] = React.useState(false);

    const redirectToOverviewScreen = () => {
        history.push('/producten');
    };

    React.useEffect(() => {
        if (hasRight(authUserReducer, Recht.PRODUCTEN_VERWIJDEREN) !== canDelete) {
            setCanDelete(hasRight(authUserReducer, Recht.PRODUCTEN_VERWIJDEREN));
        }
        if (hasRight(authUserReducer, Recht.IS_SUPERUSER) || (authUserReducer.beheerder && !authUserReducer.beheerder.uitgever)) {
            // gebruiker is superuser of is overkoepelende beheerder (als in: wel Topicus maar geen superuser)
            setCanSelectOrganisation(true);
        }
        if (postSuccess) {
            redirectToOverviewScreen();
        }
    }, [canDelete, authUserReducer, entity, postSuccess]);

    const _doDelete = (entity, deleteItem) => {
        let deletingItem = deleteItem({endpoint, id: entity.id});

        deletingItem = toastPromise(
            deletingItem,
            'Aan het verwijderen...',
            'Het product is verwijderd.',
            (err) => {
                if (err.status === 409) {
                    let msg =
                        'Dit kan nu niet worden verwijderd omdat er nog andere entiteiten aan zijn gekoppeld. Verwijder eerst:\n';
                    if (err.failedEntities.summary) {
                        msg += err.failedEntities.summary;
                    } else {
                        for (let entity of err.failedEntities) {
                            msg += `${entity.toString} (${entity.entityType}#${entity.id})\n`;
                        }
                    }
                    return msg;
                } else {
                    return `Het product kon niet worden verwijderd${err.message ? ': ' + err.message : ''}.`;
                }
            },
            null,
            true
        );

        deletingItem.then(() => {
            redirectToOverviewScreen();
        });
    };
    const _toggleActivation = (values, setFieldValue) => {
        setFieldValue(
            'customProperties.productState',
            values.activationToggleEndDate < new Date() ? 'NIET_MEER_LEVERBAAR' : 'LEVERBAAR',
            false
        );
        setFieldValue('endOfLifeDate', values.activationToggleEndDate, false);

        if (values.licenseModel.licenseActivationType === 'PERIODE') {
            setFieldValue('licenseModel.activatableUntil', values.activationToggleEndDate, false);
        }
    };

    const _detectEsc = (e) => {
        if (e.keyCode === 27) redirectToOverviewScreen();
    };

    const chainFilter = (values, chain, content) => {
        return (values.chains.includes(chain) || values.chains.length === 0) && content;
    };

    const resetChainSpecificFields = (chainType, setFieldValue) => {
        switch (chainType) {
            case 'ECK':
                setFieldValue('deliverable', false, false);
                setFieldValue('customProperties.catalogItem', false, false);
                setFieldValue('customProperties.courses', [], false);
                setFieldValue('customProperties.levels', [], false);
                setFieldValue('customProperties.authors', [], false);
                break;
            case 'BASISPOORT':
                setFieldValue('customProperties.productFamilyThumbnailLocation', '', false);
                break;
        }
    };

    const _toggleEckOrderAllowed = (values) => {
        if (!values.customProperties.catalogItem) {
            return false;
        } else if (values.customProperties.catalogItem === true && values.customProperties.eckOrderAllowed === undefined) {
            return true;
        } else {
            return !!values.customProperties.eckOrderAllowed;
        }
    };

    const handleChainsOnChange = (values, setFieldValue) => {
        return () => {
            if (values.chains.includes('BASISPOORT') && !values.chains.includes('ECK')) {
                setFieldValue('customProperties.sectors', ['PO'], false);
            } else if (values.chains.includes('BASISPOORT') && !values.customProperties.sectors.includes('PO')) {
                setFieldValue('customProperties.sectors', [...values.customProperties.sectors, 'PO'], false);
            } else if (values.chains.length === 0) {
                setFieldValue('customProperties.sectors', [], false);
            }

            if (values.chains.includes('ECK')) {
                setFieldValue('deliverable', true, false);
            } else {
                resetChainSpecificFields('ECK', setFieldValue, false);
            }

            if (!values.chains.includes('BASISPOORT')) {
                resetChainSpecificFields('BASISPOORT', setFieldValue, false);
            }
        };
    };

    const generateCheckboxGroup = (
        values,
        errors,
        setFieldValue,
        label,
        field,
        options,
        required,
        additionalOnChangeLogic,
        disabledCheckboxes
    ) => {
        return (
            <FormControl className={classes.checkboxContainer} required={required || false}>
                <FormLabel component="legend" className={classes.topSpacing}>
                    {label}
                </FormLabel>
                {get(errors, field) && <span className={classes.error}>{get(errors, field)}</span>}
                <FormGroup className={classes.checkboxGrid}>
                    {options.map((option) => {
                        return (
                            <FormControlLabel
                                className={classes.checkbox}
                                control={
                                    <Checkbox
                                        color="primary"
                                        disabled={disabledCheckboxes?.includes(option.value)}
                                        checked={get(values, field, []).includes(option.value)}
                                        onChange={(e) => {
                                            const type = option.value;
                                            if (e.target.checked && !get(values, field, []).includes(type)) {
                                                const tempType = get(values, field, []);
                                                tempType.push(type);
                                                setFieldValue(field, tempType, false);
                                            } else if (!e.target.checked && get(values, field, []).includes(type)) {
                                                const tempType = get(values, field, []);
                                                remove(tempType, function (x) {
                                                    return x === type;
                                                });
                                                setFieldValue(field, tempType, false);
                                            }
                                            if (additionalOnChangeLogic) {
                                                additionalOnChangeLogic();
                                            }
                                        }}
                                    />
                                }
                                label={option.label || option.value}
                            />
                        );
                    })}
                </FormGroup>
            </FormControl>
        );
    };

    const normalizeProductState = (productState) => {
        switch (productState) {
            case 'NIET_MEER_LEVERBAAR':
                return 'niet meer leverbaar';
            case 'LEVERBAAR':
                return 'leverbaar';
            case 'BEPERKT_LEVERBAAR':
                return 'beperkt leverbaar';
            case 'TIJDELIJK_NIET_LEVERBAAR':
                return 'tijdelijk niet leverbaar';
            case 'ZAL_NIET_VERSCHIJNEN':
                return 'zal niet verschijnen';
            case 'NOG_NIET_LEVERBAAR':
                return 'nog niet leverbaar';
        }
    };
    return (
        <CardContent className={classes.root}>
            <ConfirmationDialog
                title="Weet je het zeker?"
                open={openDelete}
                onConfirm={() => {
                    _doDelete(entity, deleteItem);
                }}
                onClose={() => setOpenDelete(false)}
            >
                {entity && entity.title
                    ? `Wil je product '${entity.title}' echt verwijderen?`
                    : 'Wil je dit product echt verwijderen?'}
            </ConfirmationDialog>
            <Typography component="h1" variant="h5" gutterBottom noWrap>
                {entity ? `Product bewerken` : `Product toevoegen`}
            </Typography>
            <Formik
                initialValues={{
                    id: entity ? entity.id : null,
                    ean: entity ? entity.ean : null,
                    subproductDigitaal: entity ? entity.subproductDigitaal : null,
                    subproductFolio: entity ? entity.subproductFolio : null,
                    productType: entity ? entity.productType : null,
                    organisation:
                        (entity && entity.organisation) ||
                        (!hasRight(authUserReducer, Recht.IS_SUPERUSER) &&
                            authUserReducer.beheerder &&
                            authUserReducer.beheerder.uitgever) ||
                        null,
                    introductionDate: entity ? dateFns.date(entity.introductionDate) : null,
                    endOfLifeDate: entity ? dateFns.date(entity.endOfLifeDate) : null,
                    activationToggleEndDate: new Date(), // Bij het activeren/deactiveren wordt dit gebruikt
                    title: entity ? entity.title : null,
                    ssoUrl: entity ? entity.ssoUrl : null,
                    serviceLicenseAllowed: entity ? entity.serviceLicenseAllowed : false,
                    deliverable: entity ? entity.deliverable : true,
                    browsers: entity && entity.browsers ? [...entity.browsers] : [],
                    authenticationType: entity && entity.authenticationType ? entity.authenticationType : 'TOEGANG_SSO',
                    platforms: entity && entity.platforms ? [...entity.platforms] : [],
                    devices: entity && entity.devices ? [...entity.devices] : [],
                    intendedEndUserRole: entity && entity.intendedEndUserRole ? entity.intendedEndUserRole : null,
                    licenseModel: {
                        id: entity?.licenseModel?.id || null,
                        description: entity?.licenseModel?.description || null,
                        licenseType: entity?.licenseModel?.licenseType || null,
                        schoolYearAmount:
                            entity?.licenseModel?.schoolYearAmount ||
                            (entity?.licenseModel?.licenseType === 'SCHOOLJAARLICENTIE' && 1) ||
                            null,
                        tiltDate: entity?.licenseModel?.tiltDate
                            ? dateFns.date(entity.licenseModel.tiltDate)
                            : new Date(1972, 6, 1),
                        schoolYearEndDate: entity?.licenseModel?.schoolYearEndDate
                            ? dateFns.date(entity.licenseModel.schoolYearEndDate)
                            : new Date(1972, 6, 31),
                        maximumLoginAmount: entity?.licenseModel?.maximumLoginAmount || null,
                        duration: entity?.licenseModel?.duration || null,
                        endDate: entity?.licenseModel?.endDate ? dateFns.date(entity?.licenseModel?.endDate) : null,
                        licenseActivationType: entity?.licenseModel?.licenseActivationType,
                        activatableFrom: entity?.licenseModel?.activatableFrom
                            ? dateFns.date(entity?.licenseModel?.activatableFrom)
                            : null,
                        activatableUntil: entity?.licenseModel?.activatableUntil
                            ? dateFns.date(entity?.licenseModel?.activatableUntil)
                            : null,
                        activatableAmountOfDays: entity?.licenseModel?.activatableAmountOfDays || null,
                    },
                    customProperties: entity
                        ? cloneDeep(entity.customProperties)
                        : {
                              authors: [],
                              sectors: [],
                              levels: [],
                              prices: [],
                              courses: [],
                              subjects: [],
                              years: [],
                              productUsages: [],
                              saleUnitSize: 1,
                              productState: 'LEVERBAAR',
                              consumptionProduct: true,
                              priceIsIndicative: true,
                              catalogItem: true,
                              productFamilyName: '',
                              productFamilyCode: '',
                          },
                    chains: entity?.chains
                        ? (entity.chains.length === 0 && ['ECK']) || [...entity.chains]
                        : ['ECK', 'BASISPOORT'],
                    publisherInfo: entity?.publisherInfo,
                    isSubject: entity ? entity.isSubject : false,
                }}
                validationSchema={getValidationSchema}
                validateOnChange={false}
                onSubmit={async (values, actions) => {
                    const {postData, updateData} = props;

                    if (values.productType !== 'WEB_BROWSER') {
                        values.licenseModel = null;
                    }

                    await _doSubmit(values, values.id ? updateData : postData, values.id ? `${endpoint}/${values.id}` : endpoint)
                        .then((result) => {
                            actions.setSubmitting(false);
                        })
                        .catch((e) => {
                            actions.setSubmitting(false);
                        });
                }}
                render={({values, touched, errors, isSubmitting, setFieldValue}) => (
                    <Form onKeyDown={_detectEsc}>
                        <Grid container direction="row">
                            <Grid xs={6} className={classes.gridContainer}>
                                <ConfirmationDialog
                                    title={`Product ${
                                        values?.endOfLifeDate > new Date()
                                            ? `'${values?.title}' deactiveren`
                                            : `'${values?.title}' activeren`
                                    }`}
                                    open={openDeactivate}
                                    onConfirm={() => {
                                        _toggleActivation(values, setFieldValue);
                                    }}
                                    onClose={(val) => setOpenDeactivate(false)}
                                    disableActionButton={errors.activationToggleEndDate}
                                    actionButtonName={values?.endOfLifeDate > new Date() ? 'Deactiveren' : 'Activeren'}
                                >
                                    <Grid container direction="column">
                                        <Grid container>
                                            <Grid item xs={12}>
                                                Huidige status:{' '}
                                                <b>{normalizeProductState(values?.customProperties.productState)}</b>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <br />
                                            Let op! Om het product te deactiveren moet de einddatum in het verleden liggen. Om het
                                            product te activeren moet de einddatum in de toekomst liggen.
                                            <FormControl className={classNames(classes.formControl, classes.fullWidth)}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                                                    <KeyboardDatePicker
                                                        clearable
                                                        autoOk
                                                        format="dd-MM-yyyy"
                                                        label="Einddatum"
                                                        margin="normal"
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                'activationToggleEndDate',
                                                                dateFns.isValid(e)
                                                                    ? dateFns.date(dateFns.format(e, 'yyyy-MM-dd'))
                                                                    : e,
                                                                false
                                                            );
                                                        }}
                                                        value={values?.activationToggleEndDate}
                                                        error={Boolean(errors.activationToggleEndDate)}
                                                        invalidDateMessage=""
                                                    />
                                                </MuiPickersUtilsProvider>
                                                {errors.activationToggleEndDate && (
                                                    <span className={classes.error}>{errors.activationToggleEndDate}</span>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </ConfirmationDialog>
                                <Grid container direction="column">
                                    <FormControl className={classes.formControl}>
                                        <InputLabel required>Titel</InputLabel>
                                        <Input
                                            error={Boolean(errors.title)}
                                            value={values.title}
                                            onChange={(e) => {
                                                setFieldValue('title', e.target.value, false);
                                            }}
                                        />
                                        {errors.title && <span className={classes.error}>{errors.title}</span>}
                                    </FormControl>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel required>EAN</InputLabel>
                                        <Input
                                            error={Boolean(errors.ean)}
                                            autoFocus={true}
                                            value={values.ean}
                                            onChange={(e) => {
                                                setFieldValue('ean', e.target.value, false);
                                            }}
                                        />
                                        {errors.ean && <span className={classes.error}>{errors.ean}</span>}
                                    </FormControl>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel>Digitaal-EAN</InputLabel>
                                        <Input
                                            error={Boolean(errors.subproductDigitaal)}
                                            value={values.subproductDigitaal}
                                            onChange={(e) => {
                                                setFieldValue('subproductDigitaal', e.target.value || null, false);
                                            }}
                                        />
                                        {errors.subproductDigitaal && <span className={classes.error}>{errors.subproductDigitaal}</span>}
                                    </FormControl>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel>Folio-EAN</InputLabel>
                                        <Input
                                            error={Boolean(errors.subproductFolio)}
                                            value={values.subproductFolio}
                                            onChange={(e) => {
                                                setFieldValue('subproductFolio', e.target.value || null, false);
                                            }}
                                        />
                                        {errors.subproductFolio && <span className={classes.error}>{errors.subproductFolio}</span>}
                                    </FormControl>
                                    {chainFilter(
                                        values,
                                        'ECK',
                                        <FormControl className={classes.formControl}>
                                            <InputLabel required>Auteurs</InputLabel>
                                            <Input
                                                multiline
                                                rows={4}
                                                error={Boolean(errors.customProperties?.authors)}
                                                value={_fromArray(values.customProperties.authors)}
                                                onChange={(e) => {
                                                    setFieldValue('customProperties.authors', _toArray(e.target.value), false);
                                                }}
                                            />
                                            {errors.customProperties?.authors && (
                                                <span className={classes.error}>{errors.customProperties.authors}</span>
                                            )}
                                        </FormControl>
                                    )}
                                    <FormControl className={classes.formControl}>
                                        {canSelectOrganisation ? (
                                            <React.Fragment>
                                                <AutoComplete
                                                    name={'organisation'}
                                                    endpoint={'organisations'}
                                                    endpointFilter={{type: 'Uitgever'}}
                                                    value={values.organisation}
                                                    labelKey={'name'}
                                                    secondLabelKey={'esn'}
                                                    valueKey={'id'}
                                                    placeholder={'Uitgever...'}
                                                    onChange={(property, value) => {
                                                        setFieldValue(property, value, false);
                                                    }}
                                                />
                                                <span className={classes.error}>
                                                    {errors.organisation && (
                                                        <span className={classes.error}>{errors.organisation}</span>
                                                    )}
                                                </span>
                                            </React.Fragment>
                                        ) : (
                                            <GenericField label="Uitgever">
                                                <ReadOnly>{values.organisation ? values.organisation.name : 'Geen'}</ReadOnly>
                                            </GenericField>
                                        )}
                                    </FormControl>
                                    {chainFilter(
                                        values,
                                        'ECK',
                                        generateCheckboxGroup(
                                            values,
                                            errors,
                                            setFieldValue,
                                            'Sectoren',
                                            'customProperties.sectors',
                                            sectorsOptions,
                                            true,
                                            null,
                                            values.chains.includes('BASISPOORT') && values.customProperties.sectors.includes('PO')
                                                ? ['PO']
                                                : []
                                        )
                                    )}
                                    {values.publisherInfo && (
                                        <FormControl className={classes.formControl}>
                                            <InputLabel>{values.publisherInfo.description}</InputLabel>
                                            <Input error={Boolean(errors.title)} disabled value={values.publisherInfo.value} />
                                        </FormControl>
                                    )}
                                    <FormControl className={classes.formControl}>
                                        <InputLabel>Methodenaam</InputLabel>
                                        <Input
                                            error={Boolean(errors.customProperties?.productFamilyName)}
                                            value={values.customProperties.productFamilyName}
                                            onChange={(e) => {
                                                setFieldValue('customProperties.productFamilyName', e.target.value, false);
                                            }}
                                        />
                                        {errors.customProperties?.productFamilyName && (
                                            <span className={classes.error}>{errors.customProperties.productFamilyName}</span>
                                        )}
                                    </FormControl>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel>Methodecode</InputLabel>
                                        <Input
                                            error={Boolean(errors.customProperties?.productFamilyCode)}
                                            value={values.customProperties.productFamilyCode}
                                            onChange={(e) => {
                                                setFieldValue('customProperties.productFamilyCode', e.target.value, false);
                                            }}
                                        />
                                        {errors.customProperties?.productFamilyCode && (
                                            <span className={classes.error}>{errors.customProperties.productFamilyCode}</span>
                                        )}
                                    </FormControl>
                                    {chainFilter(
                                        values,
                                        'ECK',
                                        <FormControl className={classes.formControl}>
                                            <InputLabel>Editie</InputLabel>
                                            <Input
                                                error={Boolean(errors.customProperties?.edition)}
                                                value={values.customProperties.edition}
                                                onChange={(e) => {
                                                    setFieldValue('customProperties.edition', e.target.value);
                                                }}
                                            />
                                            {errors.customProperties?.edition && (
                                                <span className={classes.error}>{errors.customProperties.edition}</span>
                                            )}
                                        </FormControl>
                                    )}
                                    {chainFilter(
                                        values,
                                        'ECK',
                                        <FormControl className={classes.formControl}>
                                            <InputLabel>Versie</InputLabel>
                                            <Input
                                                error={Boolean(errors.customProperties?.version)}
                                                value={values.customProperties.version}
                                                onChange={(e) => {
                                                    setFieldValue('customProperties.version', e.target.value);
                                                }}
                                            />
                                            {errors.customProperties?.version && (
                                                <span className={classes.error}>{errors.customProperties.version}</span>
                                            )}
                                        </FormControl>
                                    )}
                                    {chainFilter(
                                        values,
                                        'ECK',
                                        <FormControl className={classes.formControl}>
                                            <InputLabel>Subjects</InputLabel>
                                            <Input
                                                multiline
                                                rows={4}
                                                error={Boolean(errors.customProperties?.subjects)}
                                                value={_fromArray(values.customProperties.subjects)}
                                                onChange={(e) => {
                                                    setFieldValue('customProperties.subjects', _toArray(e.target.value));
                                                }}
                                            />
                                            {errors.customProperties?.subjects && (
                                                <span className={classes.error}>{errors.customProperties.subjects}</span>
                                            )}
                                        </FormControl>
                                    )}
                                    {chainFilter(
                                        values,
                                        'ECK',
                                        generateCheckboxGroup(
                                            values,
                                            errors,
                                            setFieldValue,
                                            'Product gebruik',
                                            'customProperties.productUsages',
                                            productUsagesOptions,
                                            false
                                        )
                                    )}
                                    {generateCheckboxGroup(
                                        values,
                                        errors,
                                        setFieldValue,
                                        'Leerjaren',
                                        'customProperties.years',
                                        yearOptions,
                                        false
                                    )}
                                    {chainFilter(
                                        values,
                                        'ECK',
                                        <FormControl className={classes.formControl}>
                                            <InputLabel required>Vakken</InputLabel>
                                            <Input
                                                multiline
                                                rows={4}
                                                error={Boolean(errors.customProperties?.courses)}
                                                value={_fromArray(values.customProperties.courses)}
                                                onChange={(e) => {
                                                    setFieldValue('customProperties.courses', _toArray(e.target.value));
                                                }}
                                            />
                                            {errors.customProperties?.courses && (
                                                <span className={classes.error}>{errors.customProperties.courses}</span>
                                            )}
                                        </FormControl>
                                    )}
                                    {chainFilter(
                                        values,
                                        'ECK',
                                        <FormControl className={classes.formControl}>
                                            <InputLabel required>Niveaus</InputLabel>
                                            <Input
                                                multiline
                                                rows={4}
                                                error={Boolean(errors.customProperties?.levels)}
                                                value={_fromArray(values.customProperties.levels)}
                                                onChange={(e) => {
                                                    setFieldValue('customProperties.levels', _toArray(e.target.value));
                                                }}
                                            />
                                            {errors.customProperties?.levels && (
                                                <span className={classes.error}>{errors.customProperties.levels}</span>
                                            )}
                                        </FormControl>
                                    )}
                                    {chainFilter(
                                        values,
                                        'ECK',
                                        <FormControl className={classes.formControl}>
                                            <InputLabel required>Verkoopeenheid</InputLabel>
                                            <Input
                                                error={Boolean(errors.customProperties?.saleUnitSize)}
                                                value={values.customProperties.saleUnitSize}
                                                onChange={(e) => {
                                                    setFieldValue('customProperties.saleUnitSize', e.target.value);
                                                }}
                                            />
                                            {errors.customProperties?.saleUnitSize && (
                                                <span className={classes.error}>{errors.customProperties.saleUnitSize}</span>
                                            )}
                                        </FormControl>
                                    )}
                                    <FormControl className={classes.formControl}>
                                        <InputLabel required>Consumentenprijs</InputLabel>
                                        <Input
                                            error={Boolean(errors.customProperties?.consumerPrice)}
                                            value={values.customProperties.consumerPrice}
                                            onChange={(e) => {
                                                setFieldValue('customProperties.consumerPrice', e.target.value);
                                            }}
                                        />
                                        {errors.customProperties?.consumerPrice && (
                                            <span className={classes.error}>{errors.customProperties.consumerPrice}</span>
                                        )}
                                    </FormControl>
                                    <FormControl className={classes.formControl}>
                                        <FormLabel component="legend" className={classes.topSpacing}>
                                            Prijzen
                                        </FormLabel>
                                        {errors.customProperties?.prices &&
                                            typeof errors.customProperties.prices === 'string' && (
                                                <span className={classes.error}>{errors.customProperties.prices}</span>
                                            )}
                                        <br />
                                        {values.customProperties.prices.map((price, index) => {
                                            return (
                                                <Grid container spacing={1} key={index}>
                                                    <Grid item container xs={11} spacing={1}>
                                                        <Grid item xs={6}>
                                                            <FormControl className={classes.formControl} fullWidth>
                                                                <InputLabel required shrink>
                                                                    Prijs excl. BTW (in centen)
                                                                </InputLabel>
                                                                <Input
                                                                    error={Boolean(
                                                                        errors.customProperties?.prices &&
                                                                            errors.customProperties.prices[index] &&
                                                                            errors.customProperties.prices[index].amount
                                                                    )}
                                                                    value={values.customProperties.prices[index].amount}
                                                                    onChange={(e) => {
                                                                        const temp = values.customProperties.prices;
                                                                        temp[index].amount = e.target.value;
                                                                        setFieldValue('customProperties.prices', temp);
                                                                    }}
                                                                />
                                                                {errors.customProperties?.prices &&
                                                                    errors.customProperties.prices[index] &&
                                                                    errors.customProperties.prices[index].amount && (
                                                                        <span className={classes.error}>
                                                                            {errors.customProperties.prices[index].amount}
                                                                        </span>
                                                                    )}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <FormControl className={classes.formControl} fullWidth>
                                                                <InputLabel required shrink>
                                                                    BTW percentage
                                                                </InputLabel>
                                                                <Input
                                                                    error={Boolean(
                                                                        errors.customProperties?.prices &&
                                                                            errors.customProperties.prices[index] &&
                                                                            errors.customProperties.prices[index].vat
                                                                    )}
                                                                    value={values.customProperties.prices[index].vat}
                                                                    onChange={(e) => {
                                                                        const temp = values.customProperties.prices;
                                                                        temp[index].vat = e.target.value;
                                                                        setFieldValue('customProperties.prices', temp);
                                                                    }}
                                                                />
                                                                {errors.customProperties?.prices &&
                                                                    errors.customProperties.prices[index] &&
                                                                    errors.customProperties.prices[index].vat && (
                                                                        <span className={classes.error}>
                                                                            {errors.customProperties.prices[index].vat}
                                                                        </span>
                                                                    )}
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton>
                                                            <CancelIcon
                                                                onClick={() => {
                                                                    const temp = values.customProperties.prices;
                                                                    temp.splice(index, 1);
                                                                    setFieldValue('customProperties.prices', temp);
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                        <Button
                                            color="primary"
                                            variant={'contained'}
                                            className={classes.colorWhite}
                                            onClick={() => {
                                                const temp = values.customProperties.prices;
                                                temp.push({amount: null, vat: null});
                                                setFieldValue('customProperties.prices', temp);
                                            }}
                                        >
                                            Prijs toevoegen
                                        </Button>
                                    </FormControl>
                                    <FormControl>
                                        <FormControlLabel
                                            className={classes.checkbox}
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    onChange={(e) => {
                                                        setFieldValue('customProperties.priceIsIndicative', e.target.checked);
                                                    }}
                                                    checked={values.customProperties.priceIsIndicative}
                                                />
                                            }
                                            label="Prijs is een indicatie"
                                        />
                                        {errors.customProperties?.priceIsIndicative &&
                                            touched.customProperties?.priceIsIndicative && (
                                                <span className={classes.error}>{errors.customProperties.priceIsIndicative}</span>
                                            )}
                                    </FormControl>
                                    <FormControl>
                                        <FormControlLabel
                                            className={classes.checkbox}
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    onChange={(e) => {
                                                        setFieldValue('isSubject', e.target.checked);
                                                    }}
                                                    checked={values.isSubject}
                                                />
                                            }
                                            label="Product is een vak voor schoolcontract"
                                        />
                                        {errors.isSubject && touched.isSubject && (
                                            <span className={classes.error}>{errors.isSubject}</span>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid xs={6} className={classes.gridContainer}>
                                <Grid container direction="column">
                                    <FormControl className={classes.formControl}>
                                        <InputLabel>Product status</InputLabel>
                                        <Select
                                            name="productState"
                                            value={values.customProperties.productState}
                                            onChange={(e) => {
                                                setFieldValue('customProperties.productState', e.target.value);
                                            }}
                                        >
                                            <MenuItem value="NOG_NIET_LEVERBAAR">Nog niet leverbaar</MenuItem>
                                            <MenuItem value="BEPERKT_LEVERBAAR">Beperkt leverbaar</MenuItem>
                                            <MenuItem value="LEVERBAAR">Leverbaar</MenuItem>
                                            <MenuItem value="TIJDELIJK_NIET_LEVERBAAR">Tijdelijk niet leverbaar</MenuItem>
                                            <MenuItem value="NIET_MEER_LEVERBAAR">Niet meer leverbaar</MenuItem>
                                            <MenuItem value="ZAL_NIET_VERSCHIJNEN">Zal niet verschijnen</MenuItem>
                                        </Select>
                                        {errors.customProperties?.productState && (
                                            <span className={classes.error}>{errors.customProperties.productState}</span>
                                        )}
                                    </FormControl>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel>Producttype</InputLabel>
                                        <Select
                                            name="productType"
                                            value={values.productType}
                                            onChange={(e) => {
                                                setFieldValue('productType', e.target.value);
                                            }}
                                        >
                                            <MenuItem value="WEB_BROWSER">Web browser</MenuItem>
                                            <MenuItem value="DOWNLOADABLE_INSTALLER">Downloadable installer</MenuItem>
                                            <MenuItem value="BOEK">Boek</MenuItem>
                                            <MenuItem value="USB">USB</MenuItem>
                                            <MenuItem value="CD_ROM">CD-ROM</MenuItem>
                                            <MenuItem value="DVD">DVD</MenuItem>
                                            <MenuItem value="ANDERS">Anders</MenuItem>
                                        </Select>
                                        {errors.productType && touched.productType && (
                                            <span className={classes.error}>{errors.productType}</span>
                                        )}
                                    </FormControl>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel>Eindgebruikersrol</InputLabel>
                                        <Select
                                            name="intendedEndUserRole"
                                            value={values.intendedEndUserRole}
                                            onChange={(e) => {
                                                setFieldValue('intendedEndUserRole', e.target.value);
                                            }}
                                        >
                                            <MenuItem value={EndUserRoleType.ONDERWIJSVOLGER}>Onderwijsvolger</MenuItem>
                                            <MenuItem value={EndUserRoleType.ONDERWIJSGEVER}>Onderwijsgever</MenuItem>
                                            <MenuItem value={EndUserRoleType.BEHEERDER}>Beheerder</MenuItem>
                                        </Select>
                                        {errors.intendedEndUserRole && touched.intendedEndUserRole && (
                                            <span className={classes.error}>{errors.intendedEndUserRole}</span>
                                        )}
                                    </FormControl>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel>Beschrijving</InputLabel>
                                        <Input
                                            multiline
                                            rows={6}
                                            error={Boolean(errors.customProperties?.description)}
                                            value={values.customProperties.description}
                                            onChange={(e) => {
                                                setFieldValue('customProperties.description', e.target.value);
                                            }}
                                        />
                                        {errors.customProperties?.description && (
                                            <span className={classes.error}>{errors.customProperties.description}</span>
                                        )}
                                    </FormControl>
                                    {generateCheckboxGroup(
                                        values,
                                        errors,
                                        setFieldValue,

                                        'Distributieketens',
                                        'chains',
                                        Object.values(ChainType).map((value) => ({value})),
                                        false,
                                        handleChainsOnChange(values, setFieldValue)
                                    )}
                                    {values && values.productType === 'WEB_BROWSER' && (
                                        <Grid container direction="column">
                                            {generateCheckboxGroup(
                                                values,
                                                errors,
                                                setFieldValue,
                                                'Browsers',
                                                'browsers',
                                                browsersOptions,
                                                true
                                            )}
                                            {generateCheckboxGroup(
                                                values,
                                                errors,
                                                setFieldValue,
                                                'Platformen',
                                                'platforms',
                                                platformOptions,
                                                false
                                            )}
                                            {generateCheckboxGroup(
                                                values,
                                                errors,
                                                setFieldValue,
                                                'Devices',
                                                'devices',
                                                deviceOptions,
                                                false
                                            )}
                                            <FormControl className={classes.formControl}>
                                                <InputLabel>Licentietype</InputLabel>
                                                <Select
                                                    name="licenseType"
                                                    value={values.licenseModel.licenseType}
                                                    onChange={(e) => {
                                                        setFieldValue('licenseModel.licenseType', e.target.value);
                                                        if (e.target.value === 'ABONNEMENT') {
                                                            setFieldValue('licenseModel.licenseActivationType', null);
                                                        }
                                                    }}
                                                >
                                                    <MenuItem value="SCHOOLJAARLICENTIE">Schooljaarlicentie</MenuItem>
                                                    <MenuItem value="VRIJE_LICENTIE">Vrije Licentie</MenuItem>
                                                    <MenuItem value="VASTE_PERIODE">Vaste Periode</MenuItem>
                                                    <MenuItem value="VASTE_DATUM">Vaste Datum</MenuItem>
                                                    <MenuItem value="AANTAL">Aantal</MenuItem>
                                                    <MenuItem value="ABONNEMENT">Abonnement</MenuItem>
                                                </Select>
                                                {errors.licenseModel?.licenseType && (
                                                    <span className={classes.error}>{errors.licenseModel?.licenseType}</span>
                                                )}
                                            </FormControl>
                                            {values.licenseModel.licenseType === 'AANTAL' && (
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel>Aantal</InputLabel>
                                                    <Input
                                                        error={Boolean(errors.licenseModel?.maximumLoginAmount)}
                                                        value={values.licenseModel.maximumLoginAmount}
                                                        onChange={(e) => {
                                                            setFieldValue('licenseModel.maximumLoginAmount', e.target.value);
                                                        }}
                                                    />
                                                    {errors.licenseModel?.maximumLoginAmount && (
                                                        <span className={classes.error}>
                                                            {errors.licenseModel?.maximumLoginAmount}
                                                        </span>
                                                    )}
                                                </FormControl>
                                            )}
                                            {(values.licenseModel.licenseType === 'VASTE_PERIODE' ||
                                                values.licenseModel.licenseType === 'ABONNEMENT') && (
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel>Aantal dagen</InputLabel>
                                                    <Input
                                                        error={Boolean(errors.licenseModel?.duration)}
                                                        value={values.licenseModel.duration}
                                                        onChange={(e) => {
                                                            setFieldValue('licenseModel.duration', e.target.value);
                                                        }}
                                                    />
                                                    {errors.licenseModel?.duration && (
                                                        <span className={classes.error}>{errors.licenseModel?.duration}</span>
                                                    )}
                                                </FormControl>
                                            )}
                                            {values.licenseModel.licenseType === 'SCHOOLJAARLICENTIE' && (
                                                <>
                                                    <FormControl className={classes.formControl}>
                                                        <InputLabel>Aantal schooljaren</InputLabel>
                                                        <Input
                                                            error={Boolean(errors.licenseModel?.schoolYearAmount)}
                                                            value={values.licenseModel.schoolYearAmount}
                                                            onChange={(e) => {
                                                                setFieldValue('licenseModel.schoolYearAmount', e.target.value);
                                                            }}
                                                        />
                                                        {errors.licenseModel?.schoolYearAmount && (
                                                            <span className={classes.error}>
                                                                {errors.licenseModel?.schoolYearAmount}
                                                            </span>
                                                        )}
                                                    </FormControl>
                                                    <MuiPickersUtilsProvider utils={CustomDateUtils} locale={nl}>
                                                        <DatePicker
                                                            label="Schooljaar einddatum"
                                                            autoOk={true}
                                                            format={'dd MMMM'}
                                                            clearable
                                                            minDate={new Date(1972, 0, 1)}
                                                            maxDate={new Date(1972, 11, 31)}
                                                            initialFocusedDate={new Date(1972, 7, 1)}
                                                            value={values.licenseModel.schoolYearEndDate}
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    'licenseModel.schoolYearEndDate',
                                                                    e ? dateFns.date(dateFns.format(e, 'yyyy-MM-dd')) : null
                                                                );
                                                            }}
                                                        />
                                                        {errors.licenseModel?.schoolYearEndDate && (
                                                            <span className={classes.error}>
                                                                {errors.licenseModel?.schoolYearEndDate}
                                                            </span>
                                                        )}
                                                    </MuiPickersUtilsProvider>
                                                    <MuiPickersUtilsProvider utils={CustomDateUtils} locale={nl}>
                                                        <DatePicker
                                                            label="Kanteldatum"
                                                            autoOk={true}
                                                            format={'dd MMMM'}
                                                            clearable
                                                            minDate={new Date(1972, 0, 1)}
                                                            maxDate={new Date(1972, 11, 31)}
                                                            initialFocusedDate={new Date(1970, 5, 1)}
                                                            value={values.licenseModel.tiltDate}
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    'licenseModel.tiltDate',
                                                                    e ? dateFns.date(dateFns.format(e, 'yyyy-MM-dd')) : null
                                                                );
                                                            }}
                                                        />
                                                        {errors.licenseModel?.tiltDate && (
                                                            <span className={classes.error}>{errors.licenseModel?.tiltDate}</span>
                                                        )}
                                                    </MuiPickersUtilsProvider>
                                                </>
                                            )}
                                            {values.licenseModel.licenseType === 'VASTE_DATUM' && (
                                                <FormControl className={classes.formControl}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                                                        <KeyboardDatePicker
                                                            label="Licentie einddatum"
                                                            autoOk={true}
                                                            format={'dd-MM-yyyy'}
                                                            clearable
                                                            margin="normal"
                                                            value={values.licenseModel.endDate}
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    'licenseModel.endDate',
                                                                    dateFns.isValid(e)
                                                                        ? dateFns.date(dateFns.format(e, 'yyyy-MM-dd'))
                                                                        : e
                                                                );
                                                            }}
                                                            error={Boolean(errors.licenseModel?.endDate)}
                                                            invalidDateMessage=""
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    {errors.licenseModel?.endDate && (
                                                        <span className={classes.error}>{errors.licenseModel?.endDate}</span>
                                                    )}
                                                </FormControl>
                                            )}
                                            <FormControl>
                                                <FormControlLabel
                                                    className={classes.checkbox}
                                                    control={
                                                        <Checkbox
                                                            color="primary"
                                                            checked={values.serviceLicenseAllowed}
                                                            onChange={(e) => {
                                                                setFieldValue('serviceLicenseAllowed', e.target.checked);
                                                            }}
                                                        />
                                                    }
                                                    label="Servicelicentie"
                                                />
                                                {errors.serviceLicenseAllowed && touched.serviceLicenseAllowed && (
                                                    <span className={classes.error}>{errors.serviceLicenseAllowed}</span>
                                                )}
                                            </FormControl>
                                            {values.licenseModel.licenseType !== 'ABONNEMENT' && (
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel>Licentie activatietype</InputLabel>
                                                    <Select
                                                        name="licenseActivationType"
                                                        value={values.licenseModel.licenseActivationType}
                                                        onChange={(e) => {
                                                            setFieldValue('licenseModel.licenseActivationType', e.target.value);
                                                        }}
                                                    >
                                                        <MenuItem value="EINDE_PRODUCT">Einde product</MenuItem>
                                                        <MenuItem value="KANTELDATUM">Kanteldatum</MenuItem>
                                                        <MenuItem value="PERIODE">Periode</MenuItem>
                                                        <MenuItem value="AANTAL_DAGEN">Aantal dagen</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            )}

                                            {values.licenseModel.licenseActivationType === 'PERIODE' && (
                                                <Grid container direction="row">
                                                    <Grid xs={5}>
                                                        <FormControl className={classes.formControl}>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                                                                <KeyboardDatePicker
                                                                    autoOk
                                                                    format="dd-MM-yyyy"
                                                                    label="Te activeren vanaf"
                                                                    margin="normal"
                                                                    error={Boolean(errors.activatableFrom)}
                                                                    onChange={(e) => {
                                                                        setFieldValue(
                                                                            'licenseModel.activatableFrom',
                                                                            dateFns.isValid(e)
                                                                                ? dateFns.date(dateFns.format(e, 'yyyy-MM-dd'))
                                                                                : e
                                                                        );
                                                                    }}
                                                                    value={values.licenseModel.activatableFrom}
                                                                    invalidDateMessage=""
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                            {errors.activatableFrom && (
                                                                <span className={classes.error}>{errors.activatableFrom}</span>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid xs={5}>
                                                        <FormControl className={classes.formControl}>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                                                                <KeyboardDatePicker
                                                                    clearable
                                                                    autoOk
                                                                    format="dd-MM-yyyy"
                                                                    label="Te activeren tot"
                                                                    margin="normal"
                                                                    error={Boolean(errors.activatableUntil)}
                                                                    onChange={(e) => {
                                                                        setFieldValue(
                                                                            'licenseModel.activatableUntil',
                                                                            dateFns.isValid(e)
                                                                                ? dateFns.date(dateFns.format(e, 'yyyy-MM-dd'))
                                                                                : e
                                                                        );
                                                                    }}
                                                                    value={values.licenseModel.activatableUntil}
                                                                    invalidDateMessage=""
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                            {errors.activatableUntil && (
                                                                <span className={classes.error}>{errors.activatableUntil}</span>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            )}

                                            {values.licenseModel.licenseActivationType === 'AANTAL_DAGEN' && (
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel>Aantal dagen activeerbaar</InputLabel>
                                                    <Input
                                                        error={Boolean(errors.licenseModel?.activatableAmountOfDays)}
                                                        value={values.licenseModel.activatableAmountOfDays}
                                                        onChange={(e) => {
                                                            setFieldValue('licenseModel.activatableAmountOfDays', e.target.value);
                                                        }}
                                                    />
                                                    {errors.licenseModel?.activatableAmountOfDays && (
                                                        <span className={classes.error}>
                                                            {errors.licenseModel?.activatableAmountOfDays}
                                                        </span>
                                                    )}
                                                </FormControl>
                                            )}

                                            <FormControl className={classes.formControl}>
                                                <InputLabel>Authenticatietype</InputLabel>
                                                <Select
                                                    name="authenticationType"
                                                    value={values.authenticationType}
                                                    onChange={(e) => {
                                                        setFieldValue('authenticationType', e.target.value);
                                                    }}
                                                >
                                                    <MenuItem value="TOEGANG_SSO">Toegang sso (token)</MenuItem>
                                                    <MenuItem value="OAUTH2">Oauth 2</MenuItem>
                                                </Select>
                                                {errors.authenticationType && touched.authenticationType && (
                                                    <span className={classes.error}>{errors.authenticationType}</span>
                                                )}
                                            </FormControl>
                                            <FormControl className={classes.formControl}>
                                                <InputLabel>SSO Url</InputLabel>
                                                <Input
                                                    error={Boolean(errors.ssoUrl)}
                                                    value={values.ssoUrl}
                                                    onChange={(e) => {
                                                        setFieldValue('ssoUrl', e.target.value);
                                                    }}
                                                />
                                                {errors.ssoUrl && <span className={classes.error}>{errors.ssoUrl}</span>}
                                            </FormControl>
                                        </Grid>
                                    )}
                                    {chainFilter(
                                        values,
                                        'ECK',
                                        <FormControl className={classes.formControl}>
                                            <InputLabel>Product Url</InputLabel>
                                            <Input
                                                error={Boolean(errors.customProperties?.productUrl)}
                                                value={values.customProperties.productUrl}
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        'customProperties.productUrl',
                                                        e.target.value.length > 0 ? e.target.value : undefined
                                                    );
                                                }}
                                            />
                                            {errors.customProperties?.productUrl && (
                                                <span className={classes.error}>{errors.customProperties.productUrl}</span>
                                            )}
                                        </FormControl>
                                    )}
                                    <FormControl className={classes.formControl} required>
                                        <InputLabel>Productthumbnail Url</InputLabel>
                                        <Input
                                            error={Boolean(errors.customProperties?.productThumbnailLocation)}
                                            value={values.customProperties.productThumbnailLocation}
                                            onChange={(e) => {
                                                setFieldValue('customProperties.productThumbnailLocation', e.target.value);
                                            }}
                                        />
                                        {errors.customProperties?.productThumbnailLocation && (
                                            <span className={classes.error}>
                                                {errors.customProperties.productThumbnailLocation}
                                            </span>
                                        )}
                                    </FormControl>
                                    {chainFilter(
                                        values,
                                        'BASISPOORT',
                                        <FormControl className={classes.formControl}>
                                            <InputLabel>Methode thumbnail Url</InputLabel>
                                            <Input
                                                error={Boolean(errors.customProperties?.productFamilyThumbnailLocation)}
                                                value={values.customProperties.productFamilyThumbnailLocation}
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        'customProperties.productFamilyThumbnailLocation',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            {errors.customProperties?.productFamilyThumbnailLocation && (
                                                <span className={classes.error}>
                                                    {errors.customProperties.productFamilyThumbnailLocation}
                                                </span>
                                            )}
                                        </FormControl>
                                    )}
                                    {chainFilter(
                                        values,
                                        'ECK',
                                        <FormControl className={classes.formControl}>
                                            <InputLabel>Contentlocation Url</InputLabel>
                                            <Input
                                                error={Boolean(errors.customProperties?.contentLocation)}
                                                value={values.customProperties.contentLocation}
                                                onChange={(e) => {
                                                    setFieldValue('customProperties.contentLocation', e.target.value);
                                                }}
                                            />
                                            {errors.customProperties?.contentLocation && (
                                                <span className={classes.error}>{errors.customProperties.contentLocation}</span>
                                            )}
                                        </FormControl>
                                    )}
                                    {chainFilter(
                                        values,
                                        'ECK',
                                        <FormControl className={classes.formControl}>
                                            <InputLabel>Informationlocation Url</InputLabel>
                                            <Input
                                                error={Boolean(errors.customProperties?.informationLocation)}
                                                value={values.customProperties.informationLocation}
                                                onChange={(e) => {
                                                    setFieldValue('customProperties.informationLocation', e.target.value);
                                                }}
                                            />
                                            {errors.customProperties?.informationLocation && (
                                                <span className={classes.error}>
                                                    {errors.customProperties.informationLocation}
                                                </span>
                                            )}
                                        </FormControl>
                                    )}
                                    <FormControl>
                                        <FormControlLabel
                                            className={classes.checkbox}
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    onChange={(e) => {
                                                        setFieldValue('customProperties.consumptionProduct', e.target.checked);
                                                    }}
                                                    checked={values.customProperties.consumptionProduct}
                                                />
                                            }
                                            label="Consumptie product"
                                        />
                                        {errors.customProperties?.consumptionProduct &&
                                            touched.customProperties?.consumptionProduct && (
                                                <span className={classes.error}>
                                                    {errors.customProperties.consumptionProduct}
                                                </span>
                                            )}
                                    </FormControl>
                                    {chainFilter(
                                        values,
                                        'ECK',
                                        <FormControl>
                                            <FormControlLabel
                                                className={classes.checkbox}
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        onChange={(e) => {
                                                            setFieldValue('deliverable', e.target.checked);
                                                        }}
                                                        checked={values.deliverable}
                                                    />
                                                }
                                                label="Uitleveren in Catalogservice"
                                            />
                                            {errors.deliverable && touched.deliverable && (
                                                <span className={classes.error}>{errors.deliverable}</span>
                                            )}
                                        </FormControl>
                                    )}
                                    {chainFilter(
                                        values,
                                        'ECK',
                                        <FormControl>
                                            <FormControlLabel
                                                className={classes.checkbox}
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        value={values.customProperties.catalogItem}
                                                        onChange={(e) => {
                                                            setFieldValue('customProperties.catalogItem', e.target.checked);
                                                            setFieldValue('customProperties.eckOrderAllowed', e.target.checked);
                                                        }}
                                                        checked={values.customProperties.catalogItem}
                                                    />
                                                }
                                                label="ECK specificaties en codes ophalen is toegestaan"
                                            />
                                            {errors.customProperties?.catalogItem && (
                                                <span className={classes.error}>{errors.customProperties.catalogItem}</span>
                                            )}
                                        </FormControl>
                                    )}
                                    {chainFilter(
                                        values,
                                        'ECK',
                                        <FormControl>
                                            <FormControlLabel
                                                disabled={values.customProperties.catalogItem == false}
                                                className={classes.checkbox}
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        value={values.customProperties.eckOrderAllowed}
                                                        onChange={(e) => {
                                                            setFieldValue('customProperties.eckOrderAllowed', e.target.checked);
                                                        }}
                                                        checked={_toggleEckOrderAllowed(values)}
                                                    />
                                                }
                                                label="ECK orders zijn toegestaan"
                                            />
                                            {errors.customProperties?.eckOrderAllowed && (
                                                <span className={classes.error}>{errors.customProperties.eckOrderAllowed}</span>
                                            )}
                                        </FormControl>
                                    )}
                                    <Grid container direction="row" justify={'space-between'}>
                                        <Grid xs={5}>
                                            <FormControl className={classNames(classes.formControl, classes.fullWidth)}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                                                    <KeyboardDatePicker
                                                        autoOk
                                                        format="dd-MM-yyyy"
                                                        label="Begindatum"
                                                        margin="normal"
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                'introductionDate',
                                                                dateFns.isValid(e)
                                                                    ? dateFns.date(dateFns.format(e, 'yyyy-MM-dd'))
                                                                    : e
                                                            );
                                                        }}
                                                        value={values.introductionDate}
                                                        error={Boolean(errors.introductionDate)}
                                                        invalidDateMessage=""
                                                    />
                                                </MuiPickersUtilsProvider>
                                                {errors.introductionDate && (
                                                    <span className={classes.error}>{errors.introductionDate}</span>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid xs={5}>
                                            <FormControl className={classNames(classes.formControl, classes.fullWidth)}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                                                    <KeyboardDatePicker
                                                        clearable
                                                        autoOk
                                                        format="dd-MM-yyyy"
                                                        label="Einddatum"
                                                        margin="normal"
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                'endOfLifeDate',
                                                                dateFns.isValid(e)
                                                                    ? dateFns.date(dateFns.format(e, 'yyyy-MM-dd'))
                                                                    : e
                                                            );
                                                        }}
                                                        value={values.endOfLifeDate}
                                                        error={Boolean(errors.endOfLifeDate)}
                                                        invalidDateMessage=""
                                                    />
                                                </MuiPickersUtilsProvider>
                                                {errors.endOfLifeDate && (
                                                    <span className={classes.error}>{errors.endOfLifeDate}</span>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className={classes.actionButtonContainer}>
                                <Button
                                    className={classNames(classes.button, classes.saveButton)}
                                    variant="contained"
                                    size="medium"
                                    color="secondary"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                    Opslaan
                                </Button>
                                <Button
                                    size="medium"
                                    className={classNames(classes.button, classes.cancelButton)}
                                    onClick={(e) => history.goBack()}
                                >
                                    <CancelIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                    Annuleren
                                </Button>
                                <Grid className={classes.fab}>
                                    {entity && canDelete && (
                                        <Fab
                                            title="Verwijderen"
                                            onClick={() => {
                                                setOpenDelete(true);
                                            }}
                                        >
                                            <DeleteForever />
                                        </Fab>
                                    )}
                                    {entity && (
                                        <Fab
                                            title={values?.endOfLifeDate > new Date() ? 'Deactiveren' : 'Activeren'}
                                            onClick={() => {
                                                setOpenDeactivate(true);
                                            }}
                                        >
                                            {values?.endOfLifeDate > new Date() ? (
                                                <StopIcon color="error" />
                                            ) : (
                                                <PlayArrowIcon color="primary" />
                                            )}
                                        </Fab>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            />
        </CardContent>
    );
}

class CustomDateUtils extends DateFnsUtils {
    getYearText(date) {
        return '';
    }

    getCalendarHeaderText(date) {
        return super.getMonthText(date);
    }

    getDatePickerHeaderText(date) {
        return `${super.getMonthText(date)} ${super.getDayText(date)}`;
    }

    getWeekdays() {
        return [];
    }
}

const _fromArray = (value) => {
    if (!value || value.length === 0) return null;

    return value.join('\n');
};

const _toArray = (value) => value.split('\n');

export default compose(
    connect(mapStateToProps, () => {
        return {};
    }),
    withRouter,
    withStyles(styles)
)(ProductForm);
