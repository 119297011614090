import DateFnsUtils from '@date-io/date-fns';
import {Button, FormControlLabel, Grid, Paper, Switch, TextField, Typography, withStyles} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Refresh, Restore} from '@material-ui/icons';
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined';
import SpeakerNotesOffOutlinedIcon from '@material-ui/icons/SpeakerNotesOffOutlined';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {nl} from 'date-fns/locale';
import React from 'react';
import {compose} from 'recompose';
import {Recht} from 'shared-types';
import uuid from 'uuid';

import AutoComplete from '../../components/autocomplete';
import DataTable from '../../components/datatable';
import {DateToLocalDateRenderer, DistributorRenderer, LinkRenderer} from '../../components/datatable/contentrenderers';
import InfoDialog from '../../components/infodialog';
import SearchTextField from '../../components/input/searchtextfield';
import {hasRight, withPermissions} from '../../components/permissions/withpermissions';
import SearchButton from '../../components/searchbutton';
import SearchQuerySetter from '../../components/searchquerysetter';
import {toastError, toastPromise, toastSuccess} from '../../components/toast';
import * as Restclient from '../../services/restclient';
import {Types, parseURLSearchParams} from '../../services/util';
import defaultConnect from '../../store/connector';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const dateFns = new DateFnsUtils();

const styles = (theme) => ({
    root: {
        padding: theme.spacing(4),
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    formInputBox: {
        width: 250,
        margin: theme.spacing(1),
    },
    formInputBoxLarge: {
        width: 350,
        margin: theme.spacing(1),
    },
    formInputBoxSmall: {
        width: 100,
        margin: theme.spacing(1),
    },
    formDateBox: {
        width: 210,
        marginLeft: theme.spacing(1),
    },
    '@keyframes rotate': {
        from: {
            transform: 'rotate(0deg)',
        },
        to: {
            transform: 'rotate(360deg)',
        },
    },
    retryingMessage: {
        animation: `$rotate .75s linear infinite`,
        color: theme.palette.primary,
        disabled: true,
    },
    searchButton: {
        margin: theme.spacing(1),
    },
    switch: {
        marginTop: '10px',
        marginRight: '10px',
    },
    historyTable: {
        width: '100%',
    },
    alignRight: {
        marginLeft: 'auto',
    },
});

const Info = (props) => (
    <a title={props.rowData && props.rowData.createdAt}>
        <Icon>access_time</Icon>
    </a>
);

const RefIdHistory = ({rowData}) => {
    const [openDialog, setOpenDialog] = React.useState(false);
    return rowData.eckLicenseReferenceIdTransitions?.filter((v) => v.fromState || v.fromReferenceId).length > 0 ? (
        <React.Fragment>
            <InfoDialog maxWidth={'md'} open={openDialog} onClose={() => setOpenDialog(false)} title={'Referenceid historie'}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Van ref. id</TableCell>
                            <TableCell>Van status</TableCell>
                            <TableCell>Naar ref. id</TableCell>
                            <TableCell>Naar status</TableCell>
                            <TableCell>Datum</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowData.eckLicenseReferenceIdTransitions.map((e) => {
                            return (
                                <TableRow key={uuid.v4()}>
                                    <TableCell>{e.fromReferenceId ? e.fromReferenceId : '-'}</TableCell>
                                    <TableCell>{e.fromState ? e.fromState : '-'}</TableCell>
                                    <TableCell>{e.toReferenceId ? e.toReferenceId : '-'}</TableCell>
                                    <TableCell>{e.toState ? e.toState : '-'}</TableCell>
                                    <TableCell>{dateFns.format(new Date(e.timestamp), 'dd-MM-yyyy HH:mm:ss')}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </InfoDialog>
            <a title="Toon historie" onClick={() => setOpenDialog(true)}>
                <Icon color="secondary">history</Icon>
            </a>
            <span title={'Actuele referenceid'}>{rowData.referenceId}</span>
        </React.Fragment>
    ) : (
        <span title={'Actuele referenceid'}>{rowData.referenceId}</span>
    );
};

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250,
        },
    },
};

const statussen = ['actief', 'nietActief', 'verlopen', 'geblokkeerd', 'nogNietActiveerbaar', 'gereserveerd', 'gedespecificeerd'];

class EckLicentieOverzicht extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            filters: parseURLSearchParams(
                {
                    q: Types.STRING,
                    product: Types.NUMBER,
                    organisation: Types.NUMBER,
                    'product.organisation': Types.STRING,
                    fromDate: Types.DATE,
                    toDate: Types.DATE,
                    showFailedOnly: Types.BOOLEAN,
                    statussen: Types.ARRAY,
                    refHistoryEnabled: {type: Types.BOOLEAN, default: false},
                    showMessages: {type: Types.BOOLEAN, default: false},
                },
                props.history
            ),
            retryingMessages: [],
        };
    }

    reloadDataTable() {
        this.setState({version: Number(Date.now())});
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleShowFailedOnlySwitch(event) {
        this.setState({filters: {...this.state.filters, showFailedOnly: event.target.checked}});
    }

    handleRefHistorySwitch(event) {
        this.setState({
            version: Number(new Date()),
            filters: {...this.state.filters, refHistoryEnabled: event.target.checked},
        });
    }

    render() {
        const {classes, authUserReducer} = this.props;

        const RefreshRenderer = ({rowData}) => {
            const rowId = rowData.id;
            return rowData && rowData.retryCount >= 5 && rowData.status === 'Error' ? (
                <IconButton
                    disabled={this.state.retryingMessages.includes(rowId)}
                    size={'small'}
                    color={'primary'}
                    onClick={() => {
                        this.setState(
                            (state, props) => ({
                                retryingMessages: [...state.retryingMessages, rowId],
                            }),
                            async () => {
                                try {
                                    await Restclient.retryGenerateLicenseMessage(rowId);
                                    if (this._isMounted) {
                                        this.setState({
                                            version: Number(new Date()),
                                        });
                                    }
                                    toastSuccess(`GenerateLicenseMessage (${rowData.referenceId}) succesvol verwerkt`);
                                } catch (e) {
                                    toastError(
                                        `GenerateLicenseMessage (${rowData.referenceId}) kon niet worden verwerkt: ${e.message}`
                                    );
                                }
                                if (this._isMounted) {
                                    this.setState((state, props) => ({
                                        retryingMessages: state.retryingMessages.filter((rm) => rm !== rowId),
                                    }));
                                }
                            }
                        );
                    }}
                >
                    <Refresh className={this.state.retryingMessages.includes(rowId) ? classes.retryingMessage : ''} />
                </IconButton>
            ) : (
                ''
            );
        };

        return (
            <Paper className={classes.root}>
                <SearchQuerySetter filters={this.state.filters} history={this.props.history} />
                <Grid container>
                    <Grid container spacing={2} xs={10}>
                        <Grid item>
                            <Typography component="h1" variant="h5">
                                {this.state.filters.showMessages ? 'Licentie berichten' : 'ECK licenties'}
                            </Typography>
                        </Grid>
                        {this.state.filters.showMessages && hasRight(authUserReducer, Recht.IS_SUPERUSER) && (
                            <Grid item>
                                <Button
                                    title={'Alle gefaalde specificaties opnieuw inschieten'}
                                    onClick={async () => {
                                        toastPromise(
                                            Restclient.retryAllGenerateLicenseMessages(),
                                            'Alle gefaalde specificaties worden opnieuw ingeschoten',
                                            () => {
                                                if (this._isMounted) {
                                                    this.setState({
                                                        version: Number(new Date()),
                                                    });
                                                }
                                                return 'Alle gefaalde specificaties zijn opnieuw ingeschoten';
                                            },
                                            (e) => e.message
                                        );
                                    }}
                                    color="primary"
                                    variant="outlined"
                                >
                                    <Restore />
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    {hasRight(authUserReducer, Recht.IS_SUPERUSER) && (
                        <Grid item className={classes.alignRight}>
                            <Button
                                title={this.state.filters.showMessages ? 'Toon ECK licenties' : 'Toon licentie berichten'}
                                onClick={() => {
                                    this.setState({
                                        filters: {...this.state.filters, showMessages: !this.state.filters.showMessages},
                                    });
                                }}
                                color="primary"
                            >
                                {this.state.filters.showMessages ? <MessageOutlinedIcon /> : <SpeakerNotesOffOutlinedIcon />}
                            </Button>
                        </Grid>
                    )}
                </Grid>
                <Grid container justify="flex-start" className={classes.inputContainer}>
                    <Grid item>
                        <FormControl className={classes.formInputBoxLarge}>
                            <SearchTextField
                                fullWidth={true}
                                label={`Reference-, distributor-, eck- of user-id`}
                                value={this.state.filters.q}
                                onEnter={() => this.reloadDataTable()}
                                onChange={(v) => {
                                    this.setState({filters: {...this.state.filters, q: v}});
                                }}
                                autoFocus={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>
                    </Grid>
                    {!this.state.filters.showMessages && (
                        <React.Fragment>
                            <Grid item>
                                <FormControl className={classes.formInputBox}>
                                    <AutoComplete
                                        name={'product'}
                                        endpoint={'products'}
                                        value={this.state.filters.product}
                                        labelKey={'toString'}
                                        secondLabelKey={'ean'}
                                        valueKey={'id'}
                                        placeholder={'Product...'}
                                        onChange={(property, value) => {
                                            this.setState({
                                                filters: {
                                                    ...this.state.filters,
                                                    product: value && value.id,
                                                },
                                            });
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl className={classes.formInputBox}>
                                    <AutoComplete
                                        name={'organisation'}
                                        endpoint={'organisations'}
                                        endpointFilter={{type: 'School'}}
                                        value={this.state.filters.organisation}
                                        labelKey={'name'}
                                        secondLabelKey={'esn'}
                                        placeholder={'School...'}
                                        onChange={(property, value) => {
                                            this.setState({
                                                filters: {
                                                    ...this.state.filters,
                                                    organisation: value && value.id,
                                                },
                                            });
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            {hasRight(authUserReducer, Recht.IS_SUPERUSER) && (
                                <Grid item>
                                    <FormControl className={classes.formInputBox}>
                                        <AutoComplete
                                            name={'uitgever'}
                                            endpoint={'organisations'}
                                            endpointFilter={{type: 'Uitgever'}}
                                            value={this.state.filters['product.organisation']}
                                            labelKey={'name'}
                                            secondLabelKey={'esn'}
                                            placeholder={'Uitgever...'}
                                            onChange={(property, value) => {
                                                this.setState({
                                                    filters: {
                                                        ...this.state.filters,
                                                        'product.organisation': value && value.id,
                                                    },
                                                });
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            )}
                            <Grid item>
                                <FormControl className={classes.formInputBox}>
                                    <Select
                                        labelId="statusLabel"
                                        multiple
                                        value={this.state.filters.statussen}
                                        onChange={(event) => {
                                            this.setState({
                                                filters: {
                                                    ...this.state.filters,
                                                    statussen: event.target.value,
                                                },
                                            });
                                        }}
                                        input={<TextField margin="dense" variant="outlined" label="status" />}
                                        renderValue={(selected) => selected.sort().join(', ')}
                                        MenuProps={MenuProps}
                                    >
                                        {statussen.map((status) => (
                                            <MenuItem key={status} value={status}>
                                                <Checkbox checked={this.state.filters.statussen.includes(status)} />
                                                <ListItemText primary={status} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl className={classes.formDateBox}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                                        <KeyboardDatePicker
                                            autoOk
                                            clearable
                                            format="dd-MM-yyyy"
                                            label="Aangemaakt vanaf"
                                            margin="dense"
                                            inputVariant="outlined"
                                            onChange={(e) => {
                                                if (e === null) {
                                                    this.setState({
                                                        filters: {
                                                            ...this.state.filters,
                                                            fromDate: null,
                                                        },
                                                    });
                                                } else if (dateFns.isValid(e)) {
                                                    this.setState({
                                                        filters: {
                                                            ...this.state.filters,
                                                            fromDate: dateFns.date(dateFns.format(e, 'yyyy-MM-dd')),
                                                        },
                                                    });
                                                }
                                            }}
                                            value={this.state.filters.fromDate}
                                            invalidDateMessage=""
                                        />
                                    </MuiPickersUtilsProvider>
                                </FormControl>
                                <FormControl className={classes.formDateBox}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                                        <KeyboardDatePicker
                                            autoOk
                                            clearable
                                            format="dd-MM-yyyy"
                                            label="Aangemaakt tot"
                                            margin="dense"
                                            inputVariant="outlined"
                                            onChange={(e) => {
                                                if (e === null) {
                                                    this.setState({
                                                        filters: {
                                                            ...this.state.filters,
                                                            toDate: null,
                                                        },
                                                    });
                                                } else if (dateFns.isValid(e)) {
                                                    this.setState({
                                                        filters: {
                                                            ...this.state.filters,
                                                            toDate: dateFns.date(dateFns.format(e, 'yyyy-MM-dd')),
                                                        },
                                                    });
                                                }
                                            }}
                                            value={this.state.filters.toDate}
                                            invalidDateMessage=""
                                        />
                                    </MuiPickersUtilsProvider>
                                </FormControl>
                            </Grid>
                        </React.Fragment>
                    )}
                    <Grid item className={classes.searchButton}>
                        <SearchButton onClick={this.reloadDataTable.bind(this)} />
                    </Grid>
                </Grid>
                <Grid container justify="flex-start" className={classes.inputContainer}>
                    {this.state.filters.showMessages && (
                        <Grid item className={classes.switch}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        size="small"
                                        checked={this.state.filters.showFailedOnly}
                                        onChange={this.handleShowFailedOnlySwitch.bind(this)}
                                        value={this.state.filters.showFailedOnly}
                                        color="primary"
                                    />
                                }
                                labelPlacement="start"
                                label={'Alleen gefaalde berichten'}
                            />
                        </Grid>
                    )}
                    {!this.state.filters.showMessages
                        && (
                            <Grid item className={classes.switch}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            size="small"
                                            checked={this.state.filters.refHistoryEnabled}
                                            onChange={this.handleRefHistorySwitch.bind(this)}
                                            value={this.state.filters.refHistoryEnabled}
                                            color="primary"
                                        />
                                    }
                                    labelPlacement="start"
                                    label={'Toon referenceid historie'}
                                />
                            </Grid>
                        )
                    }
                </Grid>
                <Grid container>
                    {this.state.filters.showMessages ? (
                        <DataTable
                            endpoint="generatelicensemessages"
                            version={this.state.version}
                            striped
                            filter={this.state.filters}
                            columns={[
                                {
                                    label: '',
                                    dataKey: 'ACTIONS',
                                    maxWidth: 50,
                                    disableSort: true,
                                    component: <Info />,
                                },
                                {
                                    label: 'Startdatum',
                                    dataKey: 'startDate',
                                    maxWidth: 150,
                                    component: <DateToLocalDateRenderer />,
                                },
                                {label: 'Referenceid(s)', component: <RefIdHistory />},
                                {label: 'Distributeur', component: <DistributorRenderer />},
                                {label: 'UserId', dataKey: 'userId', maxWidth: 150},
                                {label: 'EckId', dataKey: 'eckId', maxWidth: 150},
                                {label: 'Status', dataKey: 'status', maxWidth: 100},
                                {
                                    label: '',
                                    dataKey: '',
                                    disableSort: true,
                                    maxWidth: 50,
                                    component: <RefreshRenderer />,
                                },
                            ]}
                            version={this.state.version}
                        />
                    ) : (
                        <Grid container>
                            <DataTable
                                endpoint="ecklicenses"
                                version={this.state.version}
                                striped
                                csv
                                filter={this.state.filters}
                                columns={[
                                    {
                                        label: '',
                                        dataKey: 'ACTIONS',
                                        disableSort: true,
                                        component: <Info />,
                                        styling: {justifyContent: 'center'},
                                    },
                                    {label: 'Product', dataKey: 'product.title'},
                                    {label: 'EAN', dataKey: 'product.ean'},
                                    {
                                        label: 'UserId',
                                        dataKey: 'userId',
                                        component: (
                                            <LinkRenderer
                                                url="/accounts?userid=<query>"
                                                data={{query: 'userId'}}
                                                title="userId"
                                            />
                                        ),
                                    },
                                    {
                                        label: 'EckId',
                                        dataKey: 'eckId',
                                        component: (
                                            <LinkRenderer url="/accounts?userid=<query>" data={{query: 'eckId'}} title="eckId" />
                                        ),
                                    },
                                    {
                                        label: 'School',
                                        dataKey: 'organisation.name',
                                        component: (
                                            <LinkRenderer
                                                url="organisaties/<id>/extended-view"
                                                data={{id: 'organisation.id'}}
                                                title="organisation.esn"
                                            />
                                        ),
                                    },
                                    {
                                        label: 'Startdatum',
                                        dataKey: 'startDate',
                                        component: <DateToLocalDateRenderer />,
                                    },
                                    {
                                        label: 'Einddatum',
                                        dataKey: 'endDate',
                                        component: <DateToLocalDateRenderer />,
                                    },
                                    {label: 'Referenceid(s)', dataKey: 'referenceId', component: <RefIdHistory />},
                                    {label: 'Distributeur', component: <DistributorRenderer />},
                                    {label: 'Status', dataKey: 'licenseState'},
                                    {label: 'EckContractId', dataKey: 'contractId'},
                                ]}
                                customCsvHeaders={[
                                    {
                                        label: 'Aangemaakt',
                                        dataKey: 'createdAt',
                                    },
                                    {
                                        label: 'Ean',
                                        dataKey: 'product.ean',
                                    },
                                    {
                                        label: 'EckId',
                                        dataKey: 'eckId',
                                    },
                                    {
                                        label: 'Titel',
                                        dataKey: 'product.title',
                                    },
                                    {
                                        label: 'DigideliveryId',
                                        dataKey: 'organisation.esn',
                                    },
                                    {
                                        label: 'Naam School',
                                        dataKey: 'organisation.name',
                                    },
                                    {
                                        label: 'Startdatum',
                                        dataKey: 'startDate',
                                    },
                                    {
                                        label: 'Einddatum',
                                        dataKey: 'endDate',
                                    },
                                    {
                                        label: 'Referenceid(s)',
                                        dataKey: 'referenceId',
                                    },
                                    {
                                        label: 'Distributeur',
                                        dataKey: 'distributor.name',
                                    },
                                    {
                                        label: 'Userid',
                                        dataKey: 'userId',
                                    },
                                    {
                                        label: 'Status',
                                        dataKey: 'licenseState',
                                    },
                                    {
                                        label: 'EckContractId',
                                        dataKey: 'contractId',
                                    },
                                ]}
                                defaultCsvHeaders={false}
                            />
                        </Grid>
                    )}
                </Grid>
            </Paper>
        );
    }
}

export default compose(defaultConnect, withPermissions(Recht.ECKLICENTIES_INZIEN), withStyles(styles))(EckLicentieOverzicht);
