import {
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Typography,
    withStyles,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import ConfirmationDialog from '../../components/confirmationdialog';
import InfoDialog from '../../components/infodialog';
import {hasRight, isUitgeverAccount, withPermissions} from '../../components/permissions/withpermissions';
import PseudoIdTool from '../../components/pseudoid/pseudoidtool';
import config from '../../config';
import defaultConnect from '../../store/connector';

const styles = (theme) => ({
    root: {
        padding: theme.spacing(4),
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
    secondTitle: {
        backgroundColor: '#00C2D1',
        color: '#fff',
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    convertToColumn: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    stickyRightBottom: {
        justifyContent: 'flex-end',
        flexDirection: 'column',
        flex: '1 0 auto',
        alignItems: 'flex-end',
    },
    actionButtonIcon: {
        color: '#fff',
    },
});

class BeheerOverzicht extends React.Component {
    componentWillMount() {
        this.setState({
            textInput: undefined,
            dialog: {
                message: '',
                open: false,
            },
            confirmOpen: false,
        });
    }

    componentWillUnmount() {
        this.props.clearImports();
    }

    render() {
        const {classes, authUserReducer, history} = this.props;

        return (
            <React.Fragment>
                <Paper className={classes.root}>
                    <Typography component="h1" variant="h5">
                        Beheer
                    </Typography>
                    {hasRight(authUserReducer, Recht.IS_SUPERUSER) && (
                        <Grid>
                            <ConfirmationDialog
                                title="Weet je het zeker?"
                                open={this.state.confirmOpen}
                                onConfirm={() => {
                                    this.doImport();
                                }}
                                onClose={() => {
                                    this.setState({confirmOpen: false});
                                }}
                            >
                                Weet je zeker dat je de data wilt importeren? Het importeren van producten kan niet worden
                                teruggedraaid
                            </ConfirmationDialog>

                            {this.state.dialog.open && (
                                <InfoDialog
                                    title={'Bericht'}
                                    open={this.state.dialog.open}
                                    onClose={() => {
                                        this.setState({dialog: {open: false, message: ''}});
                                    }}
                                >
                                    {this.state.dialog.message}
                                </InfoDialog>
                            )}

                            <Typography component="h1" variant="h6" gutterBottom noWrap className={classes.secondTitle}>
                                Productbeheer
                            </Typography>
                            <Grid container spacing={2}>
                                {config.toeg_env !== 'productie' && (
                                    <Grid item md={6} xs={12}>
                                        <Card className={classes.convertToColumn}>
                                            <CardContent>
                                                <Typography gutterBottom component="h1" variant="h6">
                                                    Importeer producten
                                                </Typography>
                                                <Typography variant="body2" component="p">
                                                    Importeer alle producten in de huidige omgeving.
                                                </Typography>
                                                <br />
                                                <input ref={this.setTextInputRef} type="file" label="file" accept=".json" />
                                                {this.props.beheerReducer.importFailed && (
                                                    <Typography variant="h6" className={classes.title}>
                                                        Het importeren is mislukt
                                                    </Typography>
                                                )}
                                                {!this.props.beheerReducer.importFailed && this.props.beheerReducer.log && (
                                                    <React.Fragment>
                                                        <Typography variant="h6" className={classes.title}>
                                                            Importeren voltooid.
                                                        </Typography>
                                                        <div>
                                                            <List>
                                                                {this.props.beheerReducer.log &&
                                                                    JSON.parse(this.props.beheerReducer.log).map((v) => {
                                                                        return (
                                                                            <ListItem dense={true}>
                                                                                <ListItemIcon>
                                                                                    <InfoIcon color="primary" />
                                                                                </ListItemIcon>
                                                                                <ListItemText primary={v} secondary={null} />
                                                                            </ListItem>
                                                                        );
                                                                    })}
                                                            </List>
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </CardContent>
                                            <CardActions className={classes.stickyRightBottom}>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    className={classes.actionButtonIcon}
                                                    onClick={() => {
                                                        this.setState({confirmOpen: true});
                                                    }}
                                                >
                                                    Importeer producten
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                )}
                                <Grid item md={6} xs={12}>
                                    <Card className={classes.convertToColumn}>
                                        <CardContent>
                                            <Typography gutterBottom component="h2" variant="h6">
                                                Exporteer producten
                                            </Typography>
                                            <Typography variant="body2" component="p">
                                                Exporteer alle producten uit de huidige omgeving.
                                            </Typography>
                                        </CardContent>
                                        <CardActions className={classes.stickyRightBottom}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                className={classes.actionButtonIcon}
                                                onClick={this.doExport.bind(this)}
                                            >
                                                Exporteer producten
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    <br />
                    {!isUitgeverAccount(authUserReducer) && (
                        <Grid>
                            <Typography component="h1" variant="h6" gutterBottom noWrap className={classes.secondTitle}>
                                Pseudo ID's
                            </Typography>

                            <Grid container spacing={2}>
                                <Grid item md={6} xs={12} className={classes.convertToColumn}>
                                    <PseudoIdTool
                                        title={'Pseudoid genereren'}
                                        subjectPlaceholder={'id van de gebruiker'}
                                        description={
                                            'Tool om pseudoid te genereren. Pseudoids zijn ids voor uitgevers om zo gebruikers per uitgever een eigen id te geven.'
                                        }
                                        buttonValue={'Genereer pseudoid'}
                                        httpMethod={'GET'}
                                        endpoint={(state) => `accounts/${state.subject}/idfor/${state.publisher.id}`}
                                        history={history}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} className={classes.convertToColumn}>
                                    <PseudoIdTool
                                        title={'Pseudoid decrypten'}
                                        description={
                                            'Tool om pseudoid te decrypten. Pseudoids zijn ids voor uitgevers om zo gebruikers per uitgever een eigen id te geven.'
                                        }
                                        subjectPlaceholder={'pseudoid van de gebruiker'}
                                        buttonValue={'Decrypt pseudoid'}
                                        httpMethod={'POST'}
                                        endpoint={(state) => `organisations/${state.publisher.id}/actions/getAccountFromPseudoid`}
                                        history={history}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {isUitgeverAccount(authUserReducer) && hasRight(authUserReducer, Recht.BEHEER_TOEGANG) && (
                        <Paper className={classes.root}>
                            <Typography component="h1" variant="h5" gutterBottom noWrap>
                                Pseudo ID's
                            </Typography>
                            <br />
                            <Grid item md={6} xs={12}>
                                <PseudoIdTool
                                    title={'Pseudoid decrypten'}
                                    description={
                                        'Tool om pseudoid te decrypten. Pseudoids zijn ids voor uitgevers om zo gebruikers per uitgever een eigen id te geven.'
                                    }
                                    subjectPlaceholder={'pseudoid van de gebruiker'}
                                    buttonValue={'Decrypt pseudoid'}
                                    httpMethod={'POST'}
                                    endpoint={(state) => `organisations/${state.publisher.id}/actions/getAccountFromPseudoid`}
                                    history={history}
                                />
                            </Grid>
                        </Paper>
                    )}
                </Paper>
            </React.Fragment>
        );
    }

    doImport() {
        let textInput = this.state.textInput;
        let props = this.props;
        try {
            if (textInput && textInput.files[0]) {
                let reader = new FileReader();
                reader.readAsText(textInput.files[0]);
                reader.addEventListener(
                    'loadend',
                    function () {
                        props.importProducts(reader.result);
                    },
                    false
                );
            } else {
                this.setState({
                    dialog: {
                        open: true,
                        message: 'Er is geen bestand geselecteerd',
                    },
                });
            }
        } catch (err) {
            this.setState({
                dialog: {
                    open: true,
                    message: 'Het importeren van de producten is niet gelukt. Probeer het opnieuw',
                },
            });
            console.error(err);
        }
    }

    async doExport() {
        let props = this.props;
        try {
            await props.exportProducts();
        } catch (err) {
            this.setState({
                dialog: {
                    open: true,
                    message: 'Het exporteren van de producten is niet gelukt. Probeer het opnieuw',
                },
            });
            console.error(err);
        }
    }
}

export default compose(defaultConnect, withPermissions(Recht.BEHEER_TOEGANG), withStyles(styles))(BeheerOverzicht);
