import DateFnsUtils from '@date-io/date-fns';
import {Paper, TextField, Typography, withStyles} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import {Info} from '@material-ui/icons';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {nl} from 'date-fns/locale';
import React from 'react';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import AutoComplete from '../../components/autocomplete';
import DataTable from '../../components/datatable';
import {hasRight, withPermissions} from '../../components/permissions/withpermissions';
import SearchButton from '../../components/searchbutton';
import SearchQuerySetter from '../../components/searchquerysetter';
import {Types, parseURLSearchParams} from '../../services/util';
import defaultConnect from '../../store/connector';

const dateFns = new DateFnsUtils();

const styles = (theme) => ({
    root: {
        padding: theme.spacing(4),
    },
    formInputBox: {
        width: 250,
        margin: theme.spacing(1),
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
});

const creationMethods = [
    'Toon alles',
    'ACTIVERINGSCODE',
    'API',
    'BASISPOORT',
    'BEHEER',
    'SERVICE',
    'ELF_SPECIFICATIE',
    'ILF_SPECIFICATIE',
    'VRIJE_LICENTIE',
    'SCHOOLJAAR_CONTRACT',
];

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250,
        },
    },
};

class TLinkLicentiesPerSchool extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: parseURLSearchParams(
                {
                    input: Types.STRING,
                    creationMethod: Types.STRING,
                    publisherId: Types.NUMBER,
                    distributorId: Types.NUMBER,
                    product: Types.NUMBER,
                    schoolId: Types.NUMBER,
                    schoolBrin: Types.STRING,
                    fromDate: Types.DATE,
                    toDate: Types.DATE,
                },
                props.history
            ),
        };
    }

    reloadDataTable() {
        this.setState({version: Number(Date.now())});
    }

    getDataTableColumns() {
        const columns = [
            {label: 'School', dataKey: 'schoolName'},
            {label: 'Distributeur', dataKey: 'distributorName'},
            {label: 'Brin', dataKey: 'schoolBrin'},
            {label: 'Product', dataKey: 'productTitle'},
            {label: 'EAN', dataKey: 'productEan'},
            {label: 'Available', dataKey: 'availableAmount'},
            {label: 'Active', dataKey: 'activeAmount'},
            {
                label: (
                    <span>
                        Service
                        <Tooltip
                            placement={'top'}
                            title={
                                <>
                                    Het aantal <u>Service</u> licenties van het totaal
                                </>
                            }
                        >
                            <Info fontSize={'small'} />
                        </Tooltip>
                    </span>
                ),
                dataKey: 'serviceAmount',
            },
            {
                label: (
                    <span>
                        Totaal
                        <Tooltip
                            placement={'top'}
                            title={
                                <>
                                    Totaal aantal <u>Available</u> en <u>Active</u> licenties
                                </>
                            }
                        >
                            <Info fontSize={'small'} />
                        </Tooltip>
                    </span>
                ),
                dataKey: 'totalAmount',
            },
        ];
        if (hasRight(this.props.authUserReducer, Recht.IS_SUPERUSER)) {
            columns.unshift({label: 'Uitgever', dataKey: 'publisherName'});
        }
        return columns;
    }

    render() {
        const {classes, authUserReducer} = this.props;
        return (
            <Paper className={classes.root}>
                <SearchQuerySetter filters={this.state.filters} history={this.props.history} />
                <Typography gutterBottom component="h1" variant="h5">
                    Licenties per school
                </Typography>
                <Grid container justify={'flex-start'} className={classes.inputContainer}>
                    {hasRight(authUserReducer, Recht.IS_SUPERUSER) && (
                        <Grid item>
                            <FormControl className={classes.formInputBox}>
                                <AutoComplete
                                    name={'organisation'}
                                    endpoint={'organisations'}
                                    endpointFilter={{type: 'Uitgever'}}
                                    value={this.state.filters.publisherId}
                                    labelKey={'name'}
                                    secondLabelKey={'esn'}
                                    placeholder={'Uitgever...'}
                                    onChange={(property, value) => {
                                        this.setState({
                                            filters: {
                                                ...this.state.filters,
                                                publisherId: value && value.id,
                                            },
                                        });
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <AutoComplete
                                name={'organisation'}
                                endpoint={'organisations'}
                                endpointFilter={{type: 'School'}}
                                value={this.state.filters.schoolId}
                                labelKey={'name'}
                                secondLabelKey={'esn'}
                                placeholder={'School...'}
                                onChange={(property, value) => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            schoolId: value && value.id,
                                            schoolBrin: value && value.brin,
                                        },
                                    });
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <AutoComplete
                                name={'organisation'}
                                endpoint={'organisations'}
                                endpointFilter={{type: 'Distributeur'}}
                                value={this.state.filters.distributorId}
                                labelKey={'name'}
                                secondLabelKey={'esn'}
                                placeholder={'Distributeur...'}
                                onChange={(property, value) => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            distributorId: value && value.id,
                                        },
                                    });
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <AutoComplete
                                name={'product'}
                                endpoint={'products'}
                                value={this.state.filters.product}
                                labelKey={'toString'}
                                secondLabelKey={'ean'}
                                placeholder={'Product...'}
                                onChange={(property, value) => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            product: value && value.id,
                                        },
                                    });
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <Select
                                value={this.state.filters.creationMethod}
                                onChange={(event) => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            creationMethod: event.target.value,
                                        },
                                    });
                                }}
                                input={<TextField margin="dense" variant="outlined" label="Type" />}
                                MenuProps={MenuProps}
                            >
                                {creationMethods?.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                                <KeyboardDatePicker
                                    autoOk
                                    clearable
                                    format="dd-MM-yyyy"
                                    label="Aangemaakt vanaf"
                                    margin="dense"
                                    inputVariant="outlined"
                                    onChange={(e) => {
                                        if (e === null) {
                                            this.setState({
                                                filters: {
                                                    ...this.state.filters,
                                                    fromDate: null,
                                                },
                                            });
                                        } else if (dateFns.isValid(e)) {
                                            this.setState({
                                                filters: {
                                                    ...this.state.filters,
                                                    fromDate: dateFns.date(dateFns.format(e, 'yyyy-MM-dd')),
                                                },
                                            });
                                        }
                                    }}
                                    value={this.state.filters.fromDate}
                                    invalidDateMessage=""
                                />
                            </MuiPickersUtilsProvider>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                                <KeyboardDatePicker
                                    autoOk
                                    clearable
                                    format="dd-MM-yyyy"
                                    label="Aangemaakt tot"
                                    margin="dense"
                                    inputVariant="outlined"
                                    onChange={(e) => {
                                        if (e === null) {
                                            this.setState({
                                                filters: {
                                                    ...this.state.filters,
                                                    toDate: null,
                                                },
                                            });
                                        } else if (dateFns.isValid(e)) {
                                            this.setState({
                                                filters: {
                                                    ...this.state.filters,
                                                    toDate: dateFns.date(dateFns.format(e, 'yyyy-MM-dd')),
                                                },
                                            });
                                        }
                                    }}
                                    value={this.state.filters.toDate}
                                    invalidDateMessage=""
                                />
                            </MuiPickersUtilsProvider>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <SearchButton onClick={this.reloadDataTable.bind(this)} />
                    </Grid>
                </Grid>
                <Grid container>
                    <DataTable
                        endpoint="tlinklicenseschooloverviews"
                        version={this.state.version}
                        columns={this.getDataTableColumns()}
                        filter={this.state.filters}
                        csv
                        customCsvHeaders={[
                            {
                                label: 'Totaal',
                                dataKey: 'totalAmount',
                            },
                        ]}
                    />
                </Grid>
            </Paper>
        );
    }
}

export default compose(defaultConnect, withPermissions(Recht.TLINKLICENTIES_INZIEN), withStyles(styles))(TLinkLicentiesPerSchool);
