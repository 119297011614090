const config = {
  oauth_idp_base: 'https://dev.toegang.org:8090/oidc',
  supportURL: 'http://support.toegang.org',
  supportPhoneNumber: '038-7114424',
  oauth_timeout: 3000,
  welcome_text: 'Welkom!',
  core_api: 'https://dev.toegang.org:8091',
  log_api: 'https://dev.toegang.org:9000',
  toeg_env: process.env.REACT_APP_TOEG_ENV || process.env.TOEG_ENV,
  base: 'https://dev.toegang.org:8090',
  branch: process.env.REACT_APP_BRANCH || process.env.BRANCH
};
if (config.toeg_env === 'local') {
  config.debug = true;
} else if (config.toeg_env === 'ontwikkel') {
  config.oauth_idp_base = 'https://idp-ontwikkel.toegang.org/oidc';
  config.core_api = 'https://api-ontwikkel.toegang.org';
  config.log_api = 'https://log-ontwikkel.toegang.org';
  config.base = 'https://ontwikkel.toegang.org';
} else if (config.toeg_env === 'buildstraat') {
  config.oauth_idp_base = "https://".concat(config.branch, "-dot-idp-dot-toegang-ontwikkel.appspot.com/oidc");
  config.core_api = "https://".concat(config.branch, "-dot-toegang-ontwikkel.appspot.com");
  config.log_api = "https://".concat(config.branch, "-dot-logging-dot-toegang-ontwikkel.appspot.com");
  config.base = "https://".concat(config.branch, "-dot-idp-dot-toegang-ontwikkel.appspot.com");
}
if (config.toeg_env === 'test') {
  config.oauth_idp_base = 'https://idp-test.toegang.org/oidc';
  config.core_api = 'https://api-test.toegang.org';
  config.log_api = 'https://log-test.toegang.org';
  config.base = 'https://test.toegang.org';
} else if (config.toeg_env === 'productie') {
  config.oauth_idp_base = 'https://idp.toegang.org/oidc';
  config.core_api = 'https://api-internal.toegang.org';
  config.log_api = 'https://log.toegang.org';
  config.base = 'https://toegang.org';
}
export default config;