import 'react-virtualized/styles.css';

import {Button, CircularProgress, Grid} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useReducer} from 'react';
import {withRouter} from 'react-router';
import {AutoSizer, SortIndicator, Table, defaultTableRowRenderer} from 'react-virtualized';
import {Column, defaultCellDataGetter} from 'react-virtualized/dist/es/Table';

import dataTableReducer, {initialState} from '../../reducers/dataTable';
import * as ActionCreators from '../../store/actioncreators';
import * as Actions from '../../store/actions';
import autodispatch from '../../store/autodispatch';
import {toastPromise} from '../toast';
import {DefaultRenderer} from './contentrenderers';

const styles = () => ({
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        marginTop: 10,
        paddingRight: '0!important',
    },
    tableRowHover: {
        '&:hover': {
            backgroundColor: '#ededed',
        },
    },
    tableRow: {
        cursor: 'pointer',
    },
    tableCell: {
        flex: 1,
        overflow: 'hidden',
    },
    tableHeader: {
        flex: 1,
        color: '#fff',
        cursor: 'default',
    },
    noClick: {
        cursor: 'default',
    },
    sortable: {
        color: '#fff',
        cursor: 'pointer',
    },
    noRow: {
        cursor: 'default',
        marginLeft: 20,
        marginTop: 10,
    },
    lastCreatedEntityEvenRow: {
        backgroundColor: '#baefea !important',
    },
    lastCreatedEntityOddRow: {
        backgroundColor: '#d5f5f2 !important',
    },
    csvButtonContainer: {
        alignSelf: 'flex-end',
        // avoid shaking the table when it appears
        position: 'absolute',
        marginTop: '-35px',
    },
    colorWhite: {
        color: '#fff',
    },
});

function DataTable(props) {
    const {
        filter,
        restrictSearchLength,
        endpoint,
        classes,
        columns,
        headerHeight,
        componentHeight = 700,
        rowHeight,
        onRowClick,
        version,
        csv,
        customCsvHeaders,
        excludeCsvHeaders,
        defaultCsvHeaders,
        history,
        ...tableProps
    } = props;

    const [state, dispatch] = useReducer(dataTableReducer, {...initialState});
    const {fetchData, sortDataTable, fetchCsv} = autodispatch(ActionCreators, dispatch);
    const {sortDirection, sortBy, sortedData, fetching, totalSize, fetchingCsv} = state;

    const doFetch = useCallback(
        (rangeStart) => {
            /*
             * Als version == 0, dan moet er geen data worden opgehaald. Zo kan ervoor worden gezorgd dat een
             * DataTable niet altijd direct alle data rendert als het zoekfilter nog leeg is. Als dat wel
             * de bedoeling is, dan kun je version initieel op een waarde > 0 zetten (bv. een timestamp: Date.now()).
             */
            if (version > 0) {
                const fetchingData = fetchData({
                    endpoint,
                    sortBy,
                    sortDirection,
                    filter,
                    rangeStart,
                });
                toastPromise(
                    fetchingData,
                    undefined,
                    undefined,
                    (e) => `Probleem tijdens het ophalen ${e.message ? ': ' + e.message : ''}.`,
                    {autoClose: 300},
                    false
                );
            }
        },
        [endpoint, sortBy, sortDirection, version]
    );

    useEffect(() => {
        doFetch(0);
    }, [endpoint, sortBy, sortDirection, doFetch, version]);

    const onScroll = useCallback(
        (event) => {
            const {scrollTop, scrollHeight, clientHeight} = event;
            if (
                !fetching &&
                (scrollTop + clientHeight) / scrollHeight > 0.9 &&
                sortedData.size > 0 &&
                sortedData.size < totalSize
            ) {
                doFetch(sortedData.size);
            }
        },
        [sortedData.size, fetching, doFetch, totalSize]
    );
    const onSort = useCallback(
        ({sortBy, sortDirection}) => {
            sortDataTable({sortBy, sortDirection});
        },
        [sortBy, sortDirection]
    );

    const getRowClassName = useCallback(
        ({index}) => {
            return clsx(classes.tableRow, classes.flexContainer, {
                [classes.tableRowHover]: index !== -1,
                [classes.lastCreatedEntityEvenRow]: index % 2 === 0 && _isLastCreatedEntity(index),
                [classes.lastCreatedEntityOddRow]: index % 2 === 1 && _isLastCreatedEntity(index),
            });
        },
        [fetching, sortedData, classes]
    );

    const cellRenderer = useCallback(
        (cellProps) => {
            const {component, rowData, styling, bodyStyling} = cellProps;
            const onRowClickGo = () => {
                if (onRowClick) onRowClick(rowData);
            };

            return (
                <TableCell
                    title={!component ? cellProps.cellData : ''}
                    component="div"
                    className={clsx(classes.tableCell, classes.flexContainer, {
                        [classes.noClick]: onRowClick === null,
                    })}
                    variant="body"
                    onClick={onRowClickGo}
                    style={{height: rowHeight, margin: 0, ...styling, ...bodyStyling}}
                >
                    {component ? React.cloneElement(component, {...cellProps}) : <DefaultRenderer {...cellProps} />}
                </TableCell>
            );
        },
        [onRowClick, classes, rowHeight]
    );

    const headerRenderer = useCallback(
        ({disableSort, label, sortBy, sortDirection, dataKey, styling}) => {
            return (
                <TableCell
                    component="div"
                    className={clsx(classes.tableHeader, classes.flexContainer, {
                        [classes.sortable]: disableSort !== true,
                    })}
                    variant="head"
                    style={{height: headerHeight, margin: 0, ...styling}}
                >
                    {React.isValidElement(label) ? label : <span>{label}</span>}
                    {sortBy === dataKey && <SortIndicator sortDirection={sortDirection} />}
                </TableCell>
            );
        },
        [headerHeight, classes]
    );

    const rowRenderer = useCallback(
        (row) => {
            const {index, className, style, key} = row;
            if (index === sortedData.size) {
                return (
                    <div {...{className, key, style}}>
                        <CircularProgress />
                    </div>
                );
            } else {
                return defaultTableRowRenderer(row);
            }
        },
        [sortedData]
    );

    function _loadLastCreatedEntities() {
        return JSON.parse(sessionStorage.getItem('last-created-entities'));
    }

    function _isLastCreatedEntity(index) {
        const {fetching, sortedData} = state;
        if (index === -1 || fetching || !lastCreatedEntities || !lastCreatedEntities.entities) {
            return false;
        }
        const entity = sortedData.get(index);
        if (entity.entityType !== lastCreatedEntities.entityType) {
            return false;
        }
        return lastCreatedEntities.entities.map((e) => e.id).includes(entity.id);
    }

    function _getCsvHeaders(defaultCsvHeaders, columns, customCsvHeaders, excludeCsvHeaders) {
        let csvHeaders = [];

        if (defaultCsvHeaders) {
            csvHeaders = [
                ...columns.filter((c) => c.dataKey && c.dataKey !== 'ACTIONS' && c.label && typeof c.label === 'string'),
                ...customCsvHeaders,
            ];
        } else {
            csvHeaders = [...customCsvHeaders];
        }

        return csvHeaders
            .filter((c) => !excludeCsvHeaders.includes(c.label))
            .reduce((a, c) => {
                a[c.label] = c.dataKey;
                return a;
            }, {});
    }

    const lastCreatedEntities = _loadLastCreatedEntities();

    return sortedData ? (
        <Grid container direction="column">
            {csv && sortedData.size > 0 && (
                <Grid item className={classes.csvButtonContainer}>
                    <Button
                        color="primary"
                        title={'Exporteren als excel'}
                        variant="contained"
                        disabled={fetchingCsv}
                        onClick={(e) => {
                            e.preventDefault();
                            const fetchingCsv = fetchCsv({
                                endpoint,
                                sortBy,
                                sortDirection,
                                filter,
                                csvHeaders: _getCsvHeaders(defaultCsvHeaders, columns, customCsvHeaders, excludeCsvHeaders),
                            }).then((r) => {
                                if (r.type === Actions.RECEIVE_CSV) {
                                    const action = () => (
                                        <Button
                                            className={classes.colorWhite}
                                            onClick={() => {
                                                history.push(`/myexports`);
                                            }}
                                        >
                                            Ga naar exports
                                        </Button>
                                    );
                                } else {
                                    throw new Error('No data');
                                }
                            });

                            toastPromise(
                                fetchingCsv,
                                'Export aan het starten...',
                                'Export gestart. Ga naar je exports pagina om de status te zien.',
                                'Mislukt: CSV kon niet worden geëxporteerd.'
                            );
                        }}
                    >
                        <CloudDownloadIcon className={classes.colorWhite} />
                    </Button>
                </Grid>
            )}
            <Grid item>
                <AutoSizer disableHeight={true}>
                    {({width}) => (
                        <Table
                            height={componentHeight}
                            width={width}
                            rowHeight={rowHeight}
                            rowClassName={getRowClassName}
                            rowCount={fetching ? sortedData.size + 1 : sortedData.size}
                            rowGetter={({index}) => sortedData.get(index)}
                            headerHeight={headerHeight}
                            headerStyle={{backgroundColor: '#00C2D1', alignItems: 'stretch', marginRight: 0}}
                            sort={onSort}
                            sortBy={sortBy}
                            sortDirection={sortDirection}
                            onScroll={onScroll}
                            rowRenderer={rowRenderer}
                            noRowsRenderer={() => <div className={classes.noRow}>Geen gegevens</div>}
                            {...tableProps}
                        >
                            {columns.map(
                                (
                                    {
                                        component,
                                        dataKey,
                                        disableSort,
                                        label,
                                        maxWidth,
                                        columnWidth,
                                        styling,
                                        bodyStyling,
                                        ...other
                                    },
                                    index
                                ) => {
                                    return (
                                        <Column
                                            flexGrow={1}
                                            maxWidth={maxWidth || width}
                                            width={columnWidth || 150}
                                            label={label}
                                            key={dataKey}
                                            dataKey={dataKey}
                                            headerRenderer={(headerProps) =>
                                                headerRenderer({
                                                    ...headerProps,
                                                    disableSort: disableSort,
                                                    styling,
                                                })
                                            }
                                            className={classes.flexContainer}
                                            cellDataGetter={_cellDataGetter}
                                            cellRenderer={(cellProps) => {
                                                return cellRenderer({
                                                    ...cellProps,
                                                    component: component,
                                                    styling,
                                                    bodyStyling,
                                                });
                                            }}
                                            disableSort={disableSort}
                                            {...other}
                                        />
                                    );
                                }
                            )}
                        </Table>
                    )}
                </AutoSizer>
            </Grid>
        </Grid>
    ) : (
        <CircularProgress />
    );
}

// getter for nested properties (like 'product.organisatie.naam')
function _cellDataGetter({dataKey, rowData}) {
    if (dataKey) {
        const dataKeys = dataKey.split('.');
        return dataKeys.reduce(twoArgDefaultCellDataGetter, rowData);
    }
}

function twoArgDefaultCellDataGetter(rowData, dataKey) {
    if (rowData === undefined) {
        return undefined;
    }
    return defaultCellDataGetter({rowData, dataKey});
}

DataTable.propTypes = {
    classes: PropTypes.object.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            component: PropTypes.object,
            dataKey: PropTypes.string.isRequired,
            disableSort: PropTypes.bool,
            label: PropTypes.string.isRequired,
            maxWidth: PropTypes.number,
        })
    ).isRequired,
    headerHeight: PropTypes.number,
    onRowClick: PropTypes.func,
    rowHeight: PropTypes.number,
    marginTop: PropTypes.number,
    marginRight: PropTypes.number,
    csv: PropTypes.bool,
    customCsvHeaders: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            dataKey: PropTypes.string.isRequired,
        })
    ),
    excludeCsvHeaders: PropTypes.arrayOf(PropTypes.string),
    defaultCsvHeaders: PropTypes.bool,
    version: PropTypes.number,
};

DataTable.defaultProps = {
    headerHeight: 48,
    rowHeight: 48,
    csv: false,
    customCsvHeaders: [],
    excludeCsvHeaders: [],
    defaultCsvHeaders: true,
};

export default withRouter(withStyles(styles)(DataTable));
