import * as isUrl from 'is-url';
/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';

function toUrl(input) {
    if (input && !input.includes('//')) {
        return 'https://' + input;
    } else if (input && input.startsWith('http://')) {
        return 'https' + input.slice(4);
    } else {
        return input;
    }
}

export default function getValidationSchema() {
    return Yup.object().shape({
        clientid: Yup.string().max(255).required(),
        redirecturis: Yup.mixed()
            .transform((input) => input.split(',').map((uri) => toUrl(uri.trim())))
            .test('redirecturis', 'Ongeldige redirecturi', (uris) => uris.every(isUrl))
            .nullable()
            .required(),
        displayname: Yup.string().max(255).required(),
        rol: Yup.string().required(),
        ownerId: Yup.string().when('rol', {
            is: 'InternalApp',
            then: Yup.string(),
            otherwise: Yup.string().required(),
        }),
        organisations: Yup.array().when('rol', {
            is: 'InternalApp',
            then: Yup.array(),
            otherwise: Yup.array().min(1).required(),
        }),
    });
}
